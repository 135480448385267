import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { getHydrantListById, saveHydrantData, updateHydrantData } from "../../redux/actions/HydrantActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import LeafletSingleMap from "../../Components/LeafletSingleMap";
import { getAddressByLatLng, getAllUsertypeUserList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { getLiveTracking } from "../../redux/actions/MapActions";
import LeafletMapAllVehicle from "../../Components/LeafletMapAllVehicle";

const LiveTrack = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    lat:0.00,
    lng:0.00
  });
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    mapReducer:{
      liveTrackingData,
      vehicleCount,
      loadingMap,
      errorsMap,
    }
  } = useSelector((state) => state);


  useEffect(() => {
    // Function to fetch live tracking data
    const fetchLiveTracking = () => {
      dispatch(getLiveTracking(auth.token));
    };
  
    // Initial fetch
    fetchLiveTracking();
  
    // Set an interval to fetch data every 30 seconds
    const intervalId = setInterval(() => {
      fetchLiveTracking();
    }, 30000); // 30,000 milliseconds = 30 seconds
  
    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [auth, dispatch]);
  

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(()=>{
    console.log('liveTrackingData',liveTrackingData);
  },[liveTrackingData])

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Live Tracking"), path: "/Live-Tracking", active: true },
        ]}
        title={t("Live Tracking")}
      />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <div style={{ position: "relative", overflow: "hidden", height: "600px" }}>
                <LeafletMapAllVehicle
                  mapHeight={"600px"}
                  vehicleData={liveTrackingData}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingMap && (<Preloader/>)}
    </>
  );
};

export default LiveTrack;