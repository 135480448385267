import React, { useContext } from "react";
import { useAccordionButton, AccordionContext } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";

const CustomToggle = ({ children, eventKey, containerClass, linkClass, callback }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <h5 className={containerClass}>
      <Link
        to="#"
        className={classNames(linkClass, {
          collapsed: !isCurrentEventKey,
        })}
        onClick={decoratedOnClick}
      >
        {children}
      </Link>
    </h5>
  );
};

export default CustomToggle;
