import React, { useState, useEffect } from 'react'
import { useTranslation } from "react-i18next";
import AuthLayout from './AuthLayout';
import { Button, Alert, Row, Col } from "react-bootstrap";
import { Navigate, Link, useLocation } from "react-router-dom";
import FormInput from '../../Components/FormInput';
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { login } from '../../redux/actions/AuthActions'

/* bottom links */
const BottomLink = () => {
    const { t } = useTranslation();
  
    return (
      <Row className="mt-3">
        <Col className="text-center">
          {/* <p>
            <Link to={"/auth/forget-password"} className="text-white-50 ms-1">
              {t("Forgot your password?")}
            </Link>
          </p> */}
        </Col>
      </Row>
    );
  };

export default function Login() {
    const { t } = useTranslation();

    const { AuthReducer: { loading, errors, isAuthenticated } } = useSelector(state => state)
    const [formData, setFormData] = useState({ username: "", password: "" })
    const dispatch = useDispatch()
    const navigate = useNavigate()


    const inputHandler = (e) => {
        const { value, name } = e.target
        setFormData({ ...formData, [name]: value })
    }

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/')
        }
        // eslint-disable-next-line
    }, [isAuthenticated])

    const submitHandler = () => {
      //console.log(formData);
      dispatch(login(formData));
    }

  return (
    <>
      <AuthLayout
        helpText={t(
          "Enter your email address and password to access admin panel."
        )}
        bottomLinks={<BottomLink />}
      >
        
          <FormInput
            label={t("Username")}
            type="text"
            errors={errors}
            name="username"
            placeholder="Enter your Username"
            onChange={inputHandler}
            containerClass={"mb-3"}
          />
          <FormInput
            label={t("Password")}
            type="text"
            name="password"
            errors={errors}
            placeholder="Enter your password"
            onChange={inputHandler}
            containerClass={"mb-3"}
          />

          <div className="text-center d-grid">
            <Button variant="primary" type="submit" disabled={loading} onClick={submitHandler} >
              {t("Log In")}
            </Button>
          </div>
      </AuthLayout>
    </>
  );
}