import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { getStateListById, saveStateData, updateStateData } from "../../redux/actions/StateActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import swal from 'sweetalert2';
import Preloader from "../../Components/Preloader";

const Dashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    stateReducer: {
      stateLists,
      loadingState,
      errorsState,
      changedStateStatus,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if(id)
    {
      dispatch(getStateListById(auth?.token, id));
    }
  }, [auth, id]);
  
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(id)
    {
      dispatch(updateStateData(auth?.token, formData,id));
    }
    else{
      dispatch(saveStateData(auth?.token, formData));
    }
  };

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(() => {
    if (errorsState && Object.keys(errorsState).length > 0) {
      if (errorsState?.status === 409) {
        swal.fire({
          show: true,
          title: "Oops!",
          text: errorsState?.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
        dispatch({ type: 'RESET_DELETE_STATE_STATUS' });
      } else if (errorsState?.status === 400) {
        // Handle other status
      } else {
        dispatch({ type: 'RESET_DELETE_STATE_STATUS' });
      }
    }
  }, [errorsState, dispatch]);

  useEffect(() => {
    if (changedStateStatus?.success) {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedStateStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_STATE_STATUS' });
      navigate(-1);
    }
  }, [changedStateStatus, dispatch, navigate]);

  useEffect(()=>{
    if(id)
    {
      if(stateLists && Object.keys(stateLists).length>0)
      {
        setFormData({
          "state_code": stateLists?.state_code,
          "state_name": stateLists?.state
        });
      }
    }
    
  },[stateLists]);

  useEffect(() => {
    setFormData({});
    dispatch({ type: 'RESET_DELETE_STATE_STATUS' });
  }, [dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      }
    }),
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("State"), path: "State" },
          { label: t("Add State"), path: "/Add-State", active: true },
        ]}
        title={t("Add State")}
      />
      <Row>
        <Col lg={4}></Col>
        <Col lg={4}>
          <Card>
            <Card.Body>
              {errorsState?.data?.message && (
                <div className="alert alert-danger">
                  {errorsState.data.message}
                </div>
              )}
              <FormInput
                label={t("State Code")}
                type="text"
                errors={errorsState?.data}
                value={formData.state_code}
                name="state_code"
                onChange={inputHandlerCaps}
                containerClass={"mb-3"}
              />

              <FormInput
                label={t("State Name")}
                type="text"
                errors={errorsState?.data}
                value={formData.state_name}
                name="state_name"
                onChange={inputHandler}
                containerClass={"mb-3"}
              />
              
              {loading ? 
                <Button color="primary" disabled>
                  <Spinner
                    className="spinner-border-sm me-1"
                    tag="span"
                    color="white"
                  />
                  Please wait ...
                </Button>
              :
                <Button type="button" onClick={handleSubmit} disabled={loading}>
                  Submit
                </Button>
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingState && (<Preloader/>)}
    </>
  );
};

export default Dashboard;