// constants
import { LayoutActionTypes } from "../Constants";

export const changeLayoutModes = (mode) => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_MODE,
  payload: mode,
});

export const changeTwoColumnThemes = (mode) => ({
  type: LayoutActionTypes.CHANGE_TWOCOLUMN_THEME,
  payload: mode,
});

export const changeLayout = (layout) => ({
  type: LayoutActionTypes.CHANGE_LAYOUT,
  payload: layout,
});

export const changeLayoutColor = (color) => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_COLOR,
  payload: color,
});

export const changeLayoutWidth = (width) => ({
  type: LayoutActionTypes.CHANGE_LAYOUT_WIDTH,
  payload: width,
});

export const changeMenuPositions = (position) => ({
  type: LayoutActionTypes.CHANGE_MENU_POSITIONS,
  payload: position,
});

export const changeSidebarTheme = (sidebarTheme) => ({
  type: LayoutActionTypes.CHANGE_SIDEBAR_THEME,
  payload: sidebarTheme,
});

export const changeSidebarType = (sidebarType) => ({
  type: LayoutActionTypes.CHANGE_SIDEBAR_TYPE,
  payload: sidebarType,
});

export const toggleSidebarUserInfo = (showSidebarUserInfo) => ({
  type: LayoutActionTypes.TOGGLE_SIDEBAR_USER_INFO,
  payload: showSidebarUserInfo,
});

export const changeTopbarTheme = (topbarTheme) => ({
  type: LayoutActionTypes.CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
});

export const toggleTwoToneIcons = (showTwoToneIcons) => ({
  type: LayoutActionTypes.TOGGLE_TWO_TONE_ICONS,
  payload: showTwoToneIcons,
});

export const showRightSidebar = () => ({
  type: LayoutActionTypes.SHOW_RIGHT_SIDEBAR,
});

export const hideRightSidebar = () => ({
  type: LayoutActionTypes.HIDE_RIGHT_SIDEBAR,
});
