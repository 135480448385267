import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { getVendorListById, updateVendorData, saveVendorData } from "../../redux/actions/VendorActions";
import { getAllStateList, getAllCityStateWiseList, getAllUsertypeUserList } from "../../redux/actions/MasterActions";
import { getAllCityList } from "../../redux/actions/MasterActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import Preloader from "../../Components/Preloader";

const Dashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [stateId, setStateId] = useState(null);
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    vendorReducer: {
      loadingVendor,
      errorsVendor,
      vendorLists,
      changedVendorStatus,
    },
    masterReducer: {
      masterStateLists,
      masterCityStateWiseLists,
      masterUsertypeUserLists,
      loadingMaster,
      errorsMaster,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (auth?.token) {
      dispatch(getAllStateList(auth.token));
      dispatch(getAllUsertypeUserList(auth.token, 13,''));
    }
  }, [auth?.token]);

  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);

  useEffect(() => {
    if (id) {
      dispatch(getVendorListById(auth?.token, id));
    }
  }, [auth?.token, id, dispatch]);

  
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(id)
    {
      dispatch(updateVendorData(auth?.token, formData,id));
    }
    else
    {
      dispatch(saveVendorData(auth?.token, formData));
    }
  };

  useEffect(()=>{
    if(id)
    {
      if(vendorLists && Object.keys(vendorLists).length>0)
      {
        setFormData({
          name: vendorLists?.name,
          mobile: vendorLists?.mobile,
          address: vendorLists?.address,
          city: vendorLists?.city_id,
          jen_id: vendorLists?.jen_id || auth?.id,
          state_id: vendorLists?.city_idVendorCities?.cities_state?.id,
        });
      }
    }
    
  },[vendorLists])

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(() => {
    if (errorsVendor && Object.keys(errorsVendor).length > 0)
    {
      if (errorsVendor?.status === 409)
      {
        swal.fire({
          show: true,
          title: "Oops!",
          text: errorsVendor?.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
        dispatch({ type: 'RESET_DELETE_VENDOR_STATUS' });
      }
      else if (errorsVendor?.status === 400)
      {
        // Handle other status
      }
      else
      {
        dispatch({ type: 'RESET_DELETE_VENDOR_STATUS' });
      }
    }
  }, [errorsVendor, dispatch]);

  useEffect(() => {
    if (changedVendorStatus?.success) {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedVendorStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_VENDOR_STATUS' });
      navigate(-1);
    }
  }, [changedVendorStatus, dispatch, navigate]);

  useEffect(() => {
    setFormData({});
    dispatch({ type: 'RESET_DELETE_VENDOR_STATUS' });
  }, [dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase();
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectHandler = (selectedData, name) => {
    const selectedId = selectedData[0].id;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedId
    }));

    if (name === "state_id") {
      setStateId(selectedId); // Set the state ID to trigger fetching city list
    }
  };

  const selectHandler2 = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData[0].value,
    }));
  };

  const selectHandlerId = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData[0].id,
    }));
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      }
    }),
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Vendor"), path: "Vendor" },
          { label: t("Add Vendor"), path: "/Add-Vendor", active: true },
        ]}
        title={t("Add Vendor")}
      />
      <Row>
        <Col lg={3}></Col>
        <Col lg={6}>
          <Card>
            <Card.Body>
              {errorsVendor?.data?.message && (
                <div className="alert alert-danger">
                  {errorsVendor?.data?.message}
                </div>
              )}

              <Row>
                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("State")}</Form.Label>
                    <Select
                      options={masterStateLists}
                      values={masterStateLists?.filter(state => state.id === formData.state_id)}
                      name="state_id"
                      labelField={"state"}
                      valueField={"id"}
                      onChange={(selectedData) => selectHandler(selectedData, "state_id")}
                      styles={customSelectStyles}
                    />
                    
                    {errorsVendor?.data?.state_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsVendor?.data?.state_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("City")}</Form.Label>
                    <Select
                      options={masterCityStateWiseLists}
                      values={masterCityStateWiseLists?.filter(city => city.id === formData.city)}
                      name="city"
                      labelField={"city_name"}
                      valueField={"id"}
                      onChange={(selectedData) => selectHandler(selectedData, "city")}
                      styles={customSelectStyles}
                    />
                    
                    {errorsVendor?.data?.city && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsVendor?.data?.city}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={6}>
                  <FormInput
                    label={t("Vendor Name")}
                    type="text"
                    errors={errorsVendor?.data}
                    value={formData.name}
                    name="name"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={6}>
                  <FormInput
                    label={t("Mobile Number")}
                    type="text"
                    errors={errorsVendor?.data}
                    value={formData.mobile}
                    name="mobile"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={6}>
                  <FormInput
                    label={t("Address")}
                    type="text"
                    errors={errorsVendor?.data}
                    value={formData.address}
                    name="address"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("J.E.N.")}</Form.Label>
                    <Select
                      options={masterUsertypeUserLists}
                      values={[]}
                      name="jen_id"
                      labelField={"name"}
                      valueField={"id"}
                      onChange={(selectedData) => selectHandlerId(selectedData, "jen_id")}
                      styles={customSelectStyles}
                    />
                    {errorsVendor?.data?.jen_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsVendor?.data?.jen_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              
              <div className="d-flex justify-content-center">
                {loading ? 
                  <Button color="primary" disabled>
                    <Spinner
                      className="spinner-border-sm me-1"
                      tag="span"
                      color="white"
                    />
                    Please wait ...
                  </Button>
                :
                  <Button type="button" onClick={handleSubmit} disabled={loading}>
                    Submit
                  </Button>
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loading && (<Preloader/>)}
      {loadingMaster && (<Preloader/>)}
      {loadingVendor && (<Preloader/>)}
    </>
  );
};

export default Dashboard;