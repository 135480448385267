// Preloader.js
import React from 'react';
import { Spinner } from 'react-bootstrap';

const Preloader = () => {
  return (
    <>
      <style>{`
        .preloader-overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 9999; /* Ensure it's on top */
          pointer-events: none; /* Disable clicks */
        }

        .preloader-content {
          text-align: center;
        }

        .preloader-content p {
          margin-top: 10px;
          font-size: 1.2em;
          color: #000; /* Text color */
        }
      `}</style>
      <div className="preloader-overlay">
        <div className="preloader-content">
          <Spinner animation="border" variant="primary" />
          <p>Loading data...</p>
        </div>
      </div>
    </>
  );
};

export default Preloader;
