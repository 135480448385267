const initialDestination = {
    destinationLists: [],
    destinationStatus: [],
    changedDestinationStatus : [],
    loadingDestination: false,
    errorsDestination: {}
}

const  destinationReducer = (state = initialDestination, action) => {
    switch (action.type) {
        case "GET_DESTINATIONLIST_PENDING":
        case "ADD_DESTINATION_PENDING":
        case "UPDATE_DESTINATION_PENDING":
        case "UPDATE_DESTINATION_STATUS_PENDING":
        case "DELETE_DESTINATION_PENDING":
        case "GET_DESTINATIONLISTBYID_PENDING":
            return { ...state, loadingDestination: true };

        case "GET_DESTINATIONLIST_FULFILLED":
            return {
                ...state,
                loadingDestination: false,
                destinationLists: action.payload,
                destinationCount: action.destinationCount
            };

        case "ADD_DESTINATION_FULFILLED":
            return {
                ...state,
                loadingDestination: false,
                changedDestinationStatus: action.payload,
            };

        case "UPDATE_DESTINATION_FULFILLED":
            return {
                ...state,
                loadingDestination: false,
                changedDestinationStatus: action.payload,
            };

        case "UPDATE_DESTINATION_STATUS_FULFILLED":
            return {
                ...state,
                loadingDestination: false,
                changedDestinationStatus: action.payload,
            };

        case "GET_DESTINATIONLISTBYID_FULFILLED":
            return {
                ...state,
                loadingDestination: false,
                destinationLists: action.payload
            };

        case "DELETE_DESTINATION_FULFILLED":
            return {
                ...state,
                loadingDestination: false,
                changedDestinationStatus: action.payload
            };

        case "RESET_DELETE_DESTINATION_STATUS":
            return {
                ...state,
                loadingDestination: false,
                changedDestinationStatus: [],
                errorsDestination:{}
            };

        case "GET_DESTINATIONLIST_REJECTED":
        case "ADD_DESTINATION_REJECTED":
        case "UPDATE_DESTINATION_REJECTED":
        case "UPDATE_DESTINATION_STATUS_REJECTED":
        case "DELETE_DESTINATION_REJECTED":
        case "GET_DESTINATIONLISTBYID_REJECTED":
            return {
                ...state,
                loadingDestination: false,
                errorsDestination: action.payload
            };

        default:
            return state;
    }
}

export default destinationReducer;