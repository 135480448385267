const initialState = {
    stateLists: [],
    stateStatus: [],
    changedStateStatus : [],
    loadingState: false,
    errorsState: {}
}

const  stateReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_STATELIST_PENDING":
        case "ADD_STATE_PENDING":
        case "UPDATE_STATE_PENDING":
        case "UPDATE_STATE_STATUS_PENDING":
        case "DELETE_STATE_PENDING":
        case "GET_STATELISTBYID_PENDING":
            return { ...state, loadingState: true };

        case "GET_STATELIST_FULFILLED":
            return {
                ...state,
                loadingState: false,
                stateLists: action.payload,
                stateCount: action.stateCount
            };

        case "ADD_STATE_FULFILLED":
            return {
                ...state,
                loadingState: false,
                changedStateStatus: action.payload,
            };

        case "UPDATE_STATE_FULFILLED":
            return {
                ...state,
                loadingState: false,
                changedStateStatus: action.payload,
            };

        case "UPDATE_STATE_STATUS_FULFILLED":
            return {
                ...state,
                loadingState: false,
                changedStateStatus: action.payload,
            };

        case "GET_STATELISTBYID_FULFILLED":
            return {
                ...state,
                loadingState: false,
                stateLists: action.payload
            };

        case "DELETE_STATE_FULFILLED":
            return {
                ...state,
                loadingState: false,
                changedStateStatus: action.payload
            };

        case "RESET_DELETE_STATE_STATUS":
            return {
                ...state,
                loadingState: false,
                changedStateStatus: [],
                errorsState:{}
            };

        case "GET_STATELIST_REJECTED":
        case "ADD_STATE_REJECTED":
        case "UPDATE_STATE_REJECTED":
        case "UPDATE_STATE_STATUS_REJECTED":
        case "DELETE_STATE_REJECTED":
        case "GET_STATELISTBYID_REJECTED":
            return {
                ...state,
                loadingState: false,
                errorsState: action.payload
            };

        default:
            return state;
    }
}

export default stateReducer;