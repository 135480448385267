import axios from "axios";

export const getDriverList =
  (token, limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_DRIVERLIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Driver`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_DRIVERLIST_FULFILLED",
        payload: response.data?.data,
        driverCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_DRIVERLIST_REJECTED",
        payload: error.response,
      });
    }
  };


  export const saveDriverData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_DRIVER_PENDING" });
  
      const response = await axios.post(
        `/api/admin/v1/Add-Driver`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_DRIVER_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_DRIVER_REJECTED", payload: error.response });
    }
  };
  

  export const getDriverListById =(token,id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_DRIVERLISTBYID_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Driver/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_DRIVERLISTBYID_FULFILLED",
        payload: response.data?.data,
        driverCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_DRIVERLISTBYID_REJECTED",
        payload: error.response,
      });
    }
  };


export const updateDriverData = (token, data, id) => async (dispatch) => {
  try {
    console.log('token, data', token, data);
    dispatch({ type: "UPDATE_DRIVER_PENDING" });

    const response = await axios.post(
      `/api/admin/v1/Update-Driver/${id}`, // Endpoint URL with City ID
      data, // Data payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_DRIVER_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_DRIVER_REJECTED", payload: error.response });
  }
};

export const updateDriverStatusData = (token, id, status) => async (dispatch) => {
  try {
    //console.log('token, data', token, data);
    dispatch({ type: "UPDATE_DRIVER_STATUS_PENDING" });
    const data={
      "status":status
    }
    const response = await axios.post(
      `/api/admin/v1/Update-Driver-Status/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_DRIVER_STATUS_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_DRIVER_STATUS_REJECTED", payload: error.response });
  }
};


export const deleteDriverData = (token, id) => async (dispatch) => {
  try {
    console.log('token, id', token, id);
    dispatch({ type: "DELETE_DRIVER_PENDING" });

    const response = await axios.delete(
      `/api/admin/v1/Delete-Driver/${id}`, // Endpoint URL with City ID
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "DELETE_DRIVER_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "DELETE_DRIVER_REJECTED", payload: error.response });
  }
};