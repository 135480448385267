import {
    MENU_ITEMS,
    TWO_COl_MENU_ITEMS
  } from "../constants/menu";
  
  const getMenuItems = () => {
    // NOTE - You can fetch from server and return here as well
    return MENU_ITEMS;
  };

  const getTwoColumnMenuItems = () => {
    // NOTE - You can fetch from server and return here as well
    return MENU_ITEMS;
  };
  
  const findAllParent = (menuItems, menuItem) => {
    let parents = [];
    const parent = findMenuItem(menuItems, menuItem["parentKey"]);
  
    if (parent) {
      parents.push(parent["key"]);
      if (parent["parentKey"])
        parents = [...parents, ...findAllParent(menuItems, parent)];
    }
  
    return parents;
  };
  
  const findMenuItem = (menuItems, menuItemKey) => {
    if (menuItems && menuItemKey) {
      for (var i = 0; i < menuItems.length; i++) {
        if (menuItems[i].key === menuItemKey) {
          return menuItems[i];
        }
        var found = findMenuItem(menuItems[i].children, menuItemKey);
        if (found) return found;
      }
    }
    return null;
  };
  
  export {
    getMenuItems,
    getTwoColumnMenuItems,
    findAllParent,
    findMenuItem,
  };
  