import axios from "axios";

export const getHydrantList =
  (token, limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_HYDRANTLIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Hydrant`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_HYDRANTLIST_FULFILLED",
        payload: response.data?.data,
        vehicleCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_HYDRANTLIST_REJECTED",
        payload: error.response,
      });
    }
  };


  export const saveHydrantData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_HYDRANT_PENDING" });
  
      const response = await axios.post(
        `/api/admin/v1/Add-HYDRANT`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_HYDRANT_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_HYDRANT_REJECTED", payload: error.response });
    }
  };
  

  export const getHydrantListById =(token,id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_HYDRANTLISTBYID_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Hydrant/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_HYDRANTLISTBYID_FULFILLED",
        payload: response.data?.data,
        vehicleCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_HYDRANTLISTBYID_REJECTED",
        payload: error.response,
      });
    }
  };


export const updateHydrantData = (token, data, id) => async (dispatch) => {
  try {
    console.log('token, data', token, data);
    dispatch({ type: "UPDATE_HYDRANT_PENDING" });

    const response = await axios.post(
      `/api/admin/v1/Update-Hydrant/${id}`, // Endpoint URL with HYDRANT ID
      data, // Data payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_HYDRANT_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_HYDRANT_REJECTED", payload: error.response });
  }
};

export const updateHydrantStatusData = (token, id, status) => async (dispatch) => {
  try {
    //console.log('token, data', token, data);
    dispatch({ type: "UPDATE_HYDRANT_STATUS_PENDING" });
    const data={
      "status":status
    }
    const response = await axios.post(
      `/api/admin/v1/Update-Hydrant-Status/${id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_HYDRANT_STATUS_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_HYDRANT_STATUS_REJECTED", payload: error.response });
  }
};


export const deleteHydrantData = (token, id) => async (dispatch) => {
  try {
    console.log('token, id', token, id);
    dispatch({ type: "DELETE_HYDRANT_PENDING" });

    const response = await axios.delete(
      `/api/admin/v1/Delete-Hydrant/${id}`, // Endpoint URL with HYDRANT ID
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "DELETE_HYDRANT_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "DELETE_HYDRANT_REJECTED", payload: error.response });
  }
};