import { configureStore } from "@reduxjs/toolkit"
import AuthReducer from "./reducers/AuthReducer"
import userReducer from "./reducers/UserReducer";
import layoutReducer from "./reducers/LayoutReducer";
import vehicleReducer from "./reducers/VehicleReducer";
import stateReducer from "./reducers/StateReducer";
import cityReducer from "./reducers/CityReducer";
import vendorReducer from "./reducers/VendorReducer";
import destinationReducer from "./reducers/DestinationReducer";
import driverReducer from "./reducers/DriverReducer";
import usertypeReducer from "./reducers/UsertypeReducer";
import masterReducer from "./reducers/MasterReducer";
import hydrantReducer from "./reducers/HydrantReducer";
import bookingReducer from "./reducers/BookingReducer";
import verifiedReportReducer from "./reducers/VerifiedReducer";
import provisionalReportReducer from "./reducers/ProvisionalReducer";
import fareMasterReducer from "./reducers/FareMasterReducer";
import generalSettingReducer from "./reducers/GeneralSettingReducer";
import mapReducer from "./reducers/MapReducer";

import {thunk} from 'redux-thunk';
const Store = configureStore({
    reducer: {
        AuthReducer,
        userReducer,  
        layoutReducer,
        vehicleReducer,
        stateReducer,
        cityReducer,
        masterReducer,
        hydrantReducer,
        vendorReducer,
        destinationReducer,
        driverReducer,
        usertypeReducer,
        bookingReducer,
        verifiedReportReducer,
        provisionalReportReducer,
        fareMasterReducer,
        generalSettingReducer,
        mapReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(thunk),
})

export default Store