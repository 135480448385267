const initialUsertype = {
    usertypeLists: [],
    usertypeStatus: [],
    changedUsertypeStatus : [],
    loadingUsertype: false,
    errorsUsertype: {}
}

const  usertypeReducer = (state = initialUsertype, action) => {
    switch (action.type) {
        case "GET_USERTYPELIST_PENDING":
        case "ADD_USERTYPE_PENDING":
        case "UPDATE_USERTYPE_PENDING":
        case "DELETE_USERTYPE_PENDING":
        case "GET_USERTYPELISTBYID_PENDING":
            return { ...state, loadingUsertype: true };

        case "GET_USERTYPELIST_FULFILLED":
            return {
                ...state,
                loadingUsertype: false,
                usertypeLists: action.payload,
                usertypeCount: action.usertypeCount
            };

        case "ADD_USERTYPE_FULFILLED":
            return {
                ...state,
                loadingUsertype: false,
                changedUsertypeStatus: action.payload,
            };

        case "UPDATE_USERTYPE_FULFILLED":
            return {
                ...state,
                loadingUsertype: false,
                changedUsertypeStatus: action.payload,
            };

        case "GET_USERTYPELISTBYID_FULFILLED":
            return {
                ...state,
                loadingUsertype: false,
                usertypeLists: action.payload
            };

        case "DELETE_USERTYPE_FULFILLED":
            return {
                ...state,
                loadingUsertype: false,
                changedUsertypeStatus: action.payload
            };

        case "RESET_DELETE_USERTYPE_STATUS":
            return {
                ...state,
                loadingUsertype: false,
                changedUsertypeStatus: [],
                errorsUsertype:{}
            };

        case "GET_USERTYPELIST_REJECTED":
        case "ADD_USERTYPE_REJECTED":
        case "UPDATE_USERTYPE_REJECTED":
        case "DELETE_USERTYPE_REJECTED":
        case "GET_USERTYPELISTBYID_REJECTED":
            return {
                ...state,
                loadingUsertype: false,
                errorsUsertype: action.payload
            };

        default:
            return state;
    }
}

export default usertypeReducer;