import React, { useEffect, useState, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { useViewport } from "../hooks/useViewport";
import LeftSidebar from "./LeftSidebar";
import { changeHTMLAttribute } from "../util/layout";
import { LayoutMode, LayoutTypes, SideBarTypes, TopbarTheme } from "../constants/layout";
import { changeSidebarType } from "../redux/actions/LayoutActions";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";


// code splitting and lazy loading
const Topbar = React.lazy(() => import("./Topbar"));
// const LeftSidebar = React.lazy(() => import("./LeftSidebar"));
// const Footer = React.lazy(() => import("./Footer"));
// const RightSidebar = React.lazy(() => import("./RightSidebar/"));

const loading = () => <div className=""></div>;

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const { width } = useViewport();

  const {
    layoutMode,
    twoColumnTheme,
    layoutColor,
    layoutWidth,
    leftSideBarTheme,
    leftSideBarType,
    topbarTheme,
    isOpenRightSideBar,
    showTwoToneIcons,
    menuPosition,
    showSidebarUserInfo,
  } = useSelector((state) => ({
    layoutMode: state.layoutReducer.layoutMode,
    twoColumnTheme: state.layoutReducer.twoColumnTheme,
    layoutColor: state.layoutReducer.layoutColor,
    layoutWidth: state.layoutReducer.layoutWidth,
    menuPosition: state.layoutReducer.menuPosition,
    leftSideBarTheme: state.layoutReducer.leftSideBarTheme,
    leftSideBarType: state.layoutReducer.leftSideBarType,
    topbarTheme: state.layoutReducer.topbarTheme,
    showTwoToneIcons: state.layoutReducer.showTwoToneIcons,
    isOpenRightSideBar: state.layoutReducer.isOpenRightSideBar,
    menuPosition:state.layoutReducer.menuPosition,
    showSidebarUserInfo:state.layoutReducer.showSidebarUserInfo
  })
);


  const [isMenuOpened, setIsMenuOpened] = useState(false);

  /*
  layout defaults
  */

  useEffect(() => {
    //console.log('window',window);
    if (window.outerWidth < 1140) {
      dispatch(changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_FULL));
    } else if (width >= 1140) {
      dispatch(changeSidebarType(SideBarTypes.LEFT_SIDEBAR_TYPE_FULL));
      document
        .getElementsByTagName("html")[0]
        .classList.remove("sidebar-enable");
    }
  }, [width, dispatch]);

  useEffect(() => {
    changeHTMLAttribute("data-layout", LayoutTypes.LAYOUT_VERTICAL);
  }, []);

  // useEffect(() => {
  //   changeHTMLAttribute("data-two-column-color", twoColumnTheme);
  // }, [twoColumnTheme]);

  useEffect(() => {
    changeHTMLAttribute("data-layout-mode", LayoutMode.LAYOUT_DEFAULT);
  }, []);

  useEffect(() => {
    changeHTMLAttribute("data-bs-theme", layoutColor);
  }, [layoutColor]);

  useEffect(() => {
    changeHTMLAttribute("data-layout-width", layoutWidth);
  }, [layoutWidth]);

  useEffect(() => {
    changeHTMLAttribute("data-menu-position", menuPosition);
  }, [menuPosition]);

  useEffect(() => {
    changeHTMLAttribute("data-menu-color", leftSideBarTheme);
  }, [leftSideBarTheme]);

  useEffect(() => {
    changeHTMLAttribute("data-sidenav-size", leftSideBarType);
  }, [leftSideBarType]);

  useEffect(() => {
    changeHTMLAttribute("data-topbar-color", TopbarTheme.TOPBAR_THEME_LIGHT);
  }, [topbarTheme]);

  

  useEffect(() => {
    changeHTMLAttribute("data-menu-icon", showTwoToneIcons ? "twotones" : "default");
  }, [showTwoToneIcons]);

  useEffect(() => {
    changeHTMLAttribute("data-sidenav-user", showSidebarUserInfo);
  }, [showSidebarUserInfo]);

  /**
   * Open the menu when having mobile screen
   */
  const openMenu = () => {
    setIsMenuOpened((prevState) => !prevState);

    if (document.body) {
      if (isMenuOpened) {
        document.body.classList.remove("sidebar-enable");
      } else {
        document.body.classList.add("sidebar-enable");
      }
    }
  };
    
  return (
    <>
      <div id="wrapper">
        <Suspense fallback={loading()}>
          <LeftSidebar isCondensed={false} hideLogo={false} />
        </Suspense>

        <div className="content-page">
          <Suspense fallback={loading()}>
            <Topbar openLeftMenuCallBack={openMenu} />
          </Suspense>

          <div className="content">
            <Suspense fallback={loading()}>
              <Outlet />
            </Suspense>
          </div>

          <Suspense fallback={loading()}>
            <Footer />
          </Suspense>
        </div>
      </div>
      {/* {isOpenRightSideBar && (
        <Suspense fallback={loading()}>
          <RightSidebar />
        </Suspense>
      )} */}
    </>
  );
};

export default Layout;
