import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Row, Col, Card, Table, Button, Accordion, Spinner, Form, Badge, } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { getassignBookingList, assignBookingData, cancelBookingData } from "../../redux/actions/BookingActions";
import RoundedPagination from "../../Components/Paginate";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import { getDisplayDateTimeFormat } from '../../util/generalUtills'
import { getCurrentBookingList } from "../../redux/actions/BookingActions";
import CustomToggle from "../../Components/CustomToggle";
import Preloader from "../../Components/Preloader";


const Dashboard = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const {
    AuthReducer: { auth,errors,loading },
    bookingReducer: {
      assignBookingList,
      loadingBooking,
      errorsBooking,
      changedBookingStatus,
    },
  } = useSelector((state) => state);

  const [vehicleId, setVehicleId] = useState("");
  const [cancelId, setCancelId] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverMobile, setDriverMobile] = useState("");
  const [formData, setFormData] = useState({});
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(100);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  

  const fetchAssignBookingList = useCallback(() => {
    if(auth?.user_type_id==13)
    {
      if (auth?.token) {
        console.log('auth',auth);
        dispatch(getassignBookingList(auth?.token, id , paginate, page, search, sort));
      }
    }
    else{
      swal.fire({
        show: true,
        title: "Warning !",
        text: "Sorry booking can be assign by only J.E.N.",
        icon: "warning",
        showConfirmButton: false,
        timer: 4500
      });
      dispatch({ type: 'RESET_DELETE_BOOKING_STATUS' });
      navigate(-1);
    }
    
  }, [auth, id, paginate, page, search, sort, dispatch]);

  useDebounce(fetchAssignBookingList, 500, [auth, id, paginate, page, search, sort]);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors])

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    dispatch(assignBookingData(auth?.token, id,vehicleId ));
  };

  const handleSubmit2 = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    dispatch(cancelBookingData(auth?.token, id,cancelId ));
  };

  useEffect(() => {
    if (assignBookingList?.bookingData) {
      setFormData({
        bookingId: assignBookingList.bookingData[0].bookingId || "",
        name: assignBookingList.bookingData[0].name || "",
        mobile: assignBookingList.bookingData[0].mobile || "",
        source_id: assignBookingList.bookingData[0].hydrantIdBookingDetailsHydrant?.address || "",
        destination_id: assignBookingList.bookingData[0].destinationIdBookingDetailsDestination?.address || "",
        booking_date: assignBookingList.bookingData[0].booking_date || "",
      });
    }
  }, [assignBookingList?.bookingData]);

  useEffect(() => {
    if(changedBookingStatus?.success) {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedBookingStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_BOOKING_STATUS' });
      navigate(-1);
    }
  }, [changedBookingStatus, dispatch, navigate]);


  // const handlePaginateChange = (e) => {
  //   const value = e.target.value === "All" ? driverCount : parseInt(e.target.value);
  //   setPaginate(value);
  //   setPage(1);
  // };

  const selectHandler = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData[0].value,
    }));
  };

  const customSelectStyles = (errorsVehicle) => ({
    control: (base, state) => ({
      ...base,
      border: `1px solid ${errorsVehicle ? 'red' : base.borderColor}`
    }),
  });

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleVehicleClick=(value) => {
    setVehicleId(value?.vehicle_id);
    setVehicleNumber(value?.vehicleIdDriverVehicle?.vehicle_number);
    setDriverName(value?.driver_name);
    setDriverMobile(value?.driver_mobile);
  };

  useEffect(() => {
    //console.log('errorsBooking',errorsBooking);
    if(errorsBooking?.status==401){
      dispatch(logout())
      toast.error(errors?.data?.message);
      navigate('/login');
      //window.location.reload();
    }
    else if(errorsBooking?.status==400){
      //dispatch({ type: 'RESET_DELETE_STATUS' });
    }
  },[errorsBooking])
 
  
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Assign Vehicle Booking"), path: "/Assign Vehicle Booking", active: true },
        ]}
        title={t("Assign Vehicle Booking")}
      />

      <Row>
        <Col lg={6}>
          <Card>
            <Card.Body>
            <div className="mb-2">
                <Row>
                  <Col lg={3}>
                    <div className="d-flex align-items-center">
                      <input
                        type="search"
                        placeholder="Search..."
                        className="form-control ms-1"
                        autoComplete="off"
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </Col>
                </Row>
              </div>

              <div>
                <Table className="mb-0 table-bordered" responsive size="sm" striped>
                  <thead>
                    <tr>
                      <th>SR No.</th>
                      <th>Vehicle Number</th>
                      <th>Driver Name</th>
                      <th>Mobile Number</th>
                      <th>Registered At</th>
                    </tr>
                  </thead>
                  <tbody>
                  {assignBookingList.length === 0 ? (
                    <tr>
                      <td colSpan="5">Only J.E.N. Can assign driver on booking</td>
                    </tr>
                  ) : (<>
                    {assignBookingList?.data?.map?.((item, index) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                          <td>
                          <Badge
                            bg=""
                            className={"badge-outline-danger"}
                            onClick={() => handleVehicleClick(item)}
                            key={index}
                          >{item?.vehicleIdDriverVehicle?.vehicle_number}</Badge></td>
                          <td>{item?.driver_name}</td>
                          <td>{item?.driver_mobile}</td>
                          <td>{getDisplayDateTimeFormat(item?.createdAt)}</td>
                        </tr>
                      );
                    })}</>
                  )}
                  </tbody>
                </Table>
              </div>
              {/* <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
                <div class="d-inline-block me-3">
                  <label class="me-1">Display :</label>
                  <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span class="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(driverCount / paginate)}</strong> </span>
                <RoundedPagination currentPage={page} totalPages={Math.ceil(driverCount / paginate)} onPageChange={(pageNumber) => setPage(pageNumber)}/>
              </div> */}
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
        <Accordion defaultActiveKey="0" id="accordion" className="mb-3">
          <Card className="mb-1">
            <Card.Header>
              <CustomToggle
                eventKey="0"
                containerClass="m-0"
                linkClass="text-dark"
              >
                <i className="mdi mdi-help-circle me-1 text-primary"></i>
                Driver Details
              </CustomToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
              <Card.Body>
                <FormInput
                  label={t("Vehicle Number")}
                  type="text"
                  errors={errorsBooking?.data}
                  value={vehicleNumber}
                  name="vehicle_id"
                  containerClass={"mb-3"}
                  disabled
                />
                <FormInput
                  label={t("Driver Name")}
                  type="text"
                  errors={errorsBooking?.data}
                  value={driverName}
                  name="driver_name"
                  containerClass={"mb-3"}
                  disabled
                />
                <FormInput
                  label={t("Driver Mobile")}
                  type="text"
                  errors={errorsBooking?.data}
                  value={driverMobile}
                  name="driver_mobile"
                  containerClass={"mb-3"}
                  disabled
                />
                {loadingBooking ? 
                  <Button color="primary" disabled>
                    <Spinner
                      className="spinner-border-sm me-1"
                      tag="span"
                      color="white"
                    />
                    Please wait ...
                  </Button>
                :
                  <Button type="button" onClick={handleSubmit} disabled={loadingBooking}>
                    Assign
                  </Button>
                }
              </Card.Body>
            </Accordion.Collapse>
          </Card>

            <Card className="mb-1">
              <Card.Header>
                <CustomToggle
                  eventKey={1}
                  containerClass="m-0"
                  linkClass="text-dark"
                >
                  <i className="mdi mdi-help-circle me-1 text-primary"></i>
                  Booking Details
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={1}>
                <Card.Body>
                  <FormInput
                    label={t("Booking Number")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={formData.bookingId}
                    name="bookingId"
                    containerClass={"mb-3"}
                    disabled
                  />

                  <FormInput
                    label={t("Customer Name")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={formData.name}
                    name="name"
                    containerClass={"mb-3"}
                    disabled
                  />

                  <FormInput
                    label={t("Customer Number")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={formData.mobile}
                    name="mobile"
                    containerClass={"mb-3"}
                    disabled
                  />

                  <FormInput
                    label={t("Source")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={formData.source_id}
                    name="source_id"
                    containerClass={"mb-3"}
                    disabled
                  />

                  <FormInput
                    label={t("Destination")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={formData.destination_id}
                    name="destination_id"
                    containerClass={"mb-3"}
                    disabled
                  />

                  <FormInput
                    label={t("Pickup Time")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={formData.booking_date}
                    name="booking_date"
                    containerClass={"mb-3"}
                    disabled
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            <Card className="mb-1">
              <Card.Header>
                <CustomToggle
                  eventKey={2}
                  containerClass="m-0"
                  linkClass="text-dark"
                >
                  <i className="mdi mdi-help-circle me-1 text-primary"></i>
                  Cancel Booking
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={2}>
                <Card.Body>
                  <FormInput
                    label={t("Booking Number")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={formData.bookingId}
                    name="job_id"
                    containerClass={"mb-3"}
                    disabled
                  />

                  <FormInput
                    label={t("Cancel Reason")}
                    type="text"
                    errors={errorsBooking?.data}
                    value={cancelId}
                    name="cancel_id"
                    containerClass={"mb-3"}
                    onChange={(e) => setCancelId(e.target.value)}
                  />

                  {loadingBooking ? 
                    <Button color="primary" disabled>
                      <Spinner
                        className="spinner-border-sm me-1"
                        tag="span"
                        color="white"
                      />
                      Please wait ...
                    </Button>
                  :
                    <Button type="button" onClick={handleSubmit2} disabled={loadingBooking}>
                      Cancel
                    </Button>
                  }
                </Card.Body>
              </Accordion.Collapse>
            </Card>

          </Accordion>
        </Col>
      </Row>
      {loadingBooking && (<Preloader/>)}

    </>
  );
};

export default Dashboard;
