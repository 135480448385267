import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardCount } from "../redux/actions/DashboardActions";
import styled from "styled-components";
import { Row, Col, Card, Table } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import StatisticsWidget from "../../Components/StatisticsWidget";


const Dashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const {
    AuthReducer: { auth , isAuthenticated },
  } = useSelector((state) => state);

  useEffect(() => {
    console.log(auth);
    if (!isAuthenticated) {
      dispatch(logout());
      navigate('/login')
    }
  }, [auth]);

  
  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: "Dashboard", path: "/", active: true },
        ]}
        title={"Dashboard"}
      />
      <Row>
        <Col md={6} xl={3}>
          <StatisticsWidget
            variant="primary"
            counterOptions={{
              prefix: "",
            }}
            description="Waiting For Driver"
            stats="5897"
            icon="fe-heart"
          />
        </Col>
        <Col md={6} xl={3}>
          <StatisticsWidget
            variant="info"
            description="Driver Assigned"
            stats="2734"
            counterOptions={{
              suffix: "",
              decimals: 0,
            }}
            icon="fe-bar-chart-line-"
          />
        </Col>
        <Col md={6} xl={3}>
          <StatisticsWidget
            variant="success"
            description="Accept"
            stats="1272"
            icon="fe-shopping-cart"
          />
        </Col>
        <Col md={6} xl={3}>
          <StatisticsWidget
            variant="warning"
            description="Reached Hydrant"
            stats="8412"
            icon="fe-eye"
          />
        </Col>
        <Col md={6} xl={3}>
          <StatisticsWidget
            variant="warning"
            description="On Going"
            stats="8412"
            icon="fe-eye"
          />
        </Col>
        <Col md={6} xl={3}>
          <StatisticsWidget
            variant="warning"
            description="Water Supplied"
            stats="812"
            icon="fe-eye"
          />
        </Col>
        <Col md={6} xl={3}>
          <StatisticsWidget
              variant="primary"
              counterOptions={{
                prefix: "",
              }}
              description="Pending Verification"
              stats="5897"
              icon="fe-heart"
            />
          </Col>
          <Col md={6} xl={3}>
          <StatisticsWidget
              variant="primary"
              counterOptions={{
                prefix: "",
              }}
              description="Partial Complete"
              stats="5897"
              icon="fe-heart"
            />
          </Col>
          <Col md={6} xl={3}>
            <StatisticsWidget
              variant="success"
              description="Cancel"
              stats="1272"
              icon="fe-shopping-cart"
            />
          </Col>
          <Col md={6} xl={3}>
            <StatisticsWidget
              variant="warning"
              description="Total"
              stats="8412"
              icon="fe-eye"
            />
          </Col>
          <Col md={6} xl={3}>
            <StatisticsWidget
              variant="warning"
              description="Total Vehicle"
              stats="8412"
              icon="fe-eye"
            />
          </Col>
          <Col md={6} xl={3}>
          <StatisticsWidget
            variant="info"
            description="Total Driver"
            stats="2734"
            counterOptions={{
              suffix: "",
              decimals: 0,
            }}
            icon="fe-bar-chart-line-"
          />
        </Col>
      </Row>
      
    </>
  );
};

export default Dashboard;
