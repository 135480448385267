const initialVendor = {
    vendorLists: [],
    vendorStatus: [],
    changedVendorStatus : [],
    loadingVendor: false,
    errorsVendor: {}
}

const  vendorReducer = (state = initialVendor, action) => {
    switch (action.type) {
        case "GET_VENDORLIST_PENDING":
        case "ADD_VENDOR_PENDING":
        case "UPDATE_VENDOR_PENDING":
        case "UPDATE_VENDOR_STATUS_PENDING":
        case "DELETE_VENDOR_PENDING":
        case "GET_VENDORLISTBYID_PENDING":
            return { ...state, loadingVendor: true };

        case "GET_VENDORLIST_FULFILLED":
            return {
                ...state,
                loadingVendor: false,
                vendorLists: action.payload,
                vendorCount: action.vendorCount
            };

        case "ADD_VENDOR_FULFILLED":
            return {
                ...state,
                loadingVendor: false,
                changedVendorStatus: action.payload,
            };

        case "UPDATE_VENDOR_FULFILLED":
            return {
                ...state,
                loadingVendor: false,
                changedVendorStatus: action.payload,
            };

        case "UPDATE_VENDOR_STATUS_FULFILLED":
            return {
                ...state,
                loadingState: false,
                changedStateStatus: action.payload,
            };

        case "GET_VENDORLISTBYID_FULFILLED":
            return {
                ...state,
                loadingVendor: false,
                vendorLists: action.payload
            };

        case "DELETE_VENDOR_FULFILLED":
            return {
                ...state,
                loadingVendor: false,
                changedVendorStatus: action.payload
            };

        case "RESET_DELETE_VENDOR_STATUS":
            return {
                ...state,
                loadingVendor: false,
                changedVendorStatus: [],
                errorsVendor:{}
            };

        case "GET_VENDORLIST_REJECTED":
        case "ADD_VENDOR_REJECTED":
        case "UPDATE_VENDOR_REJECTED":
        case "UPDATE_VENDOR_STATUS_REJECTED":
        case "DELETE_VENDOR_REJECTED":
        case "GET_VENDORLISTBYID_REJECTED":
            return {
                ...state,
                loadingVendor: false,
                errorsVendor: action.payload
            };

        default:
            return state;
    }
}

export default vendorReducer;