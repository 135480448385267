import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/images/marker-shadow.png';
import { getDisplayDateTimeFormat } from '../util/generalUtills';

// Define custom icons (rotatable using a DivIcon)
const createRotatedCarIcon = (heading) => {
  return L.divIcon({
    className: "custom-div-icon",
    html: `<div style="transform: rotate(${heading}deg);">
             <img src="https://phedrserver.asginnovations.in/car.png" style="width: 35px; height: 35px;" alt="Car Icon" />
           </div>`,
    iconSize: [25, 35],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'leaflet/dist/images/marker-shadow.png',
    shadowSize: [41, 41],
  });
};

// Helper component to fit map bounds based on vehicle positions
const FitMapBounds = ({ vehicleData }) => {
  const map = useMap();

  useEffect(() => {
    if (vehicleData.length > 0) {
      const bounds = L.latLngBounds(vehicleData.map(vehicle => [parseFloat(vehicle.lat), parseFloat(vehicle.lng)]));
      map.fitBounds(bounds); // Fit the map to show all vehicle markers
    }
  }, [vehicleData, map]);

  return null;
};

const LeafletMapAllVehicle = ({ mapHeight, vehicleData }) => {
  return (
    <div style={{ height: mapHeight }}>
      <MapContainer center={[0, 0]} zoom={13} style={{ height: '100%' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* Fit the map to the bounds of all vehicle markers */}
        <FitMapBounds vehicleData={vehicleData} />
        {/* Loop through the vehicleData array to render each vehicle marker */}
        {vehicleData.map((vehicle) => (
          <Marker
            key={vehicle.id}
            position={[parseFloat(vehicle.lat), parseFloat(vehicle.lng)]}
            icon={createRotatedCarIcon(vehicle.heading)} // Use heading to rotate the marker
          >
            <Popup>
              <div>
                <strong>Vehicle Number:</strong> {vehicle?.vehicle_number}<br />
                <strong>Model:</strong> {vehicle?.vehicle_model}<br />
                <strong>Status:</strong> {vehicle?.status}<br />
                <strong>Speed:</strong> {vehicle?.speed} km/h<br />
                <strong>Accuracy:</strong> {vehicle?.accuracy} m<br />
                <strong>Last Location:</strong> {getDisplayDateTimeFormat(vehicle?.lastLocation)}<br />
                <strong>Vendor:</strong> {vehicle?.vendorIdVehicleVendor.name} ({vehicle.vendorIdVehicleVendor.mobile})<br />
                <strong>Driver:</strong> {vehicle.user_idVehicleAdminUsers.name} ({vehicle.user_idVehicleAdminUsers.address})<br />
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default LeafletMapAllVehicle;
