const initialState = {
    vehicleLists: [],
    vehicleStatus: [],
    changedVehicleStatus : [],
    loadingVehicle: false,
    errorsVehicle: {}
}

const  vehicleReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_VEHICLELIST_PENDING":
        case "ADD_VEHICLE_PENDING":
        case "UPDATE_VEHICLE_PENDING":
        case "UPDATE_VEHICLE_STATUS_PENDING":
        case "DELETE_VEHICLE_PENDING":
        case "GET_VEHICLELISTBYID_PENDING":
            return { ...state, loadingVehicle: true };

        case "GET_VEHICLELIST_FULFILLED":
            return {
                ...state,
                loadingVehicle: false,
                vehicleLists: action.payload,
                vehicleCount: action.vehicleCount
            };

        case "ADD_VEHICLE_FULFILLED":
            return {
                ...state,
                loadingVehicle: false,
                changedVehicleStatus: action.payload,
            };

        case "UPDATE_VEHICLE_FULFILLED":
            return {
                ...state,
                loadingVehicle: false,
                changedVehicleStatus: action.payload,
            };

        case "UPDATE_VEHICLE_STATUS_FULFILLED":
            return {
                ...state,
                loadingVehicle: false,
                changedVehicleStatus: action.payload,
            };

        case "GET_VEHICLELISTBYID_FULFILLED":
            return {
                ...state,
                loadingVehicle: false,
                vehicleLists: action.payload
            };

        case "DELETE_VEHICLE_FULFILLED":
            return {
                ...state,
                loadingVehicle: false,
                changedVehicleStatus: action.payload
            };

        case "RESET_DELETE_VEHICLE_STATUS":
            return {
                ...state,
                loadingVehicle: false,
                changedVehicleStatus: [],
                errorsVehicle:{}
            };

        case "GET_VEHICLELIST_REJECTED":
        case "ADD_VEHICLE_REJECTED":
        case "UPDATE_VEHICLE_REJECTED":
        case "UPDATE_VEHICLE_STATUS_REJECTED":
        case "DELETE_VEHICLE_REJECTED":
        case "GET_VEHICLELISTBYID_REJECTED":
            return {
                ...state,
                loadingVehicle: false,
                errorsVehicle: action.payload
            };

        default:
            return state;
    }
}

export default vehicleReducer;