import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { saveUserData, updateUserData, getUserListById } from "../../redux/actions/UserActions";
import { getAllStateList, getAllCityStateWiseList, getMasterUsertypeList, getAllUsertypeUserList, getMasterUsertypeUserWiseList } from "../../redux/actions/MasterActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import HyperDatepicker from "../../Components/Datepicker";
import {getDateTimeMysqlGivenFormatYmd } from '../../util/generalUtills'
import Preloader from "../../Components/Preloader";

const Dashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [stateId, setStateId] = useState(null);
  const [typeId, setTypeId] = useState(null);
  const [showReporting, setShowReporting] = useState(false);
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    userReducer: {
      userLists,
      loadingUser,
      errorsUser,
      changedUserStatus,
    },
    masterReducer: {
      masterStateLists,
      masterCityStateWiseLists,
      masterUsertypeLists,
      masterUsertypeUserLists,
      loadingMaster,
      errorsMaster,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (auth?.token) {
      dispatch(getAllStateList(auth.token));
      dispatch(getMasterUsertypeUserWiseList(auth.token));
    }
  }, [auth?.token]);

  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(id){
      dispatch(updateUserData(auth?.token, formData,id));
    }
    else{
      dispatch(saveUserData(auth?.token, formData));
    }
  };

  useEffect(() => {
    if(id)
    {
      dispatch(getUserListById(auth?.token, id));
    }
  }, [auth, id]);

  useEffect(()=>{
    if(id)
    {
      if(userLists && Object.keys(userLists).length>0)
      {
        setFormData({
          state_id: userLists?.city?.cities_state?.id,
          city_id: userLists?.city_id,
          user_type_id: userLists?.user_type_id,
          reporting_id: userLists?.reporting_id,
          name: userLists?.name,
          contact: userLists?.contact,
          email: userLists?.email,
          address: userLists?.address,
          username: userLists?.username,
          password: userLists?.password,
        });
      }
    }
    
  },[userLists])

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(() => {
    if (errorsUser && Object.keys(errorsUser).length > 0) {
      if (errorsUser?.status === 409) {
        swal.fire({
          show: true,
          title: "Oops!",
          text: errorsUser?.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
        dispatch({ type: 'RESET_DELETE_USER_STATUS' });
      } else if (errorsUser?.status === 400) {
        // Handle other status
      } else {
        dispatch({ type: 'RESET_DELETE_USER_STATUS' });
      }
    }
  }, [errorsUser, dispatch]);

  useEffect(() => {
    if (changedUserStatus?.success) {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedUserStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_USER_STATUS' });
      navigate(-1);
    }
  }, [changedUserStatus, dispatch, navigate]);

  // Reset formData and errorsUser when the component mounts
  useEffect(() => {
    setFormData({});
    dispatch({ type: 'RESET_DELETE_USER_STATUS' });
  }, [dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectHandler = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData[0].value,
    }));
  };

  const selectHandler2 = (selectedData, name) => {
    const selectedId = selectedData[0].id;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedId
    }));

    if (name === "state_id") {
      setStateId(selectedId);
    }

    if (name === "user_type_id") {
      const type = selectedData[0].type;
      const reporting = selectedData[0].reporting;
      console.log("sele ",type);
      if (type === "1")
      {
        dispatch(getAllUsertypeUserList(auth.token, reporting,formData.city_id));
        //setTypeId(selectedUserType?.reporting);
        setShowReporting(true);
      }
      else
      {
        setShowReporting(false);
      }
    }
    
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errorsUser?.data?.vehicle_model ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errorsUser?.data?.vehicle_model ? 'red' : base.borderColor,
      }
    }),
  };

  const onDateChange = (name,value) => {
    if (value) {
      setFormData({ ...formData, [name]: getDateTimeMysqlGivenFormatYmd(value) });
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("User"), path: "Users" },
          { label: t("Add User"), path: "/Add-User", active: true },
        ]}
        title={t("Add User")}
      />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              {errors?.data?.message && (
                <div className="alert alert-danger">
                  {errors.data.message}
                </div>
              )}
              <Row>
                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("State")}</Form.Label>
                    <Select
                      options={masterStateLists}
                      values={masterStateLists?.filter(state => state.id === formData.state_id)}
                      name="state_id"
                      labelField={"state"}
                      valueField={"id"}
                      onChange={(selectedData) => selectHandler2(selectedData, "state_id")}
                      styles={customSelectStyles}
                    />
                    
                    {errorsUser?.data?.state_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsUser?.data?.state_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("City")}</Form.Label>
                    <Select
                      options={masterCityStateWiseLists}
                      values={masterCityStateWiseLists?.filter(city => city.id === formData.city_id)}
                      name="city_id"
                      labelField={"city_name"}
                      valueField={"id"}
                      onChange={(selectedData) => selectHandler2(selectedData, "city_id")}
                      styles={customSelectStyles}
                    />
                    
                    {errorsUser?.data?.city_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsUser?.data?.city_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Usertype")}</Form.Label>
                    <Select
                      options={masterUsertypeLists}
                      values={masterUsertypeLists?.filter(usertype => usertype.id === formData.user_type_id)}
                      name="user_type_id"
                      labelField={"name"}
                      valueField={"id"}
                      onChange={(selectedData) => selectHandler2(selectedData, "user_type_id")}
                      styles={customSelectStyles}
                    />
                    
                    {errorsUser?.data?.user_type_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsUser?.data?.user_type_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                {showReporting && (
                  <Col lg={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("Reporting To")}</Form.Label>
                      <Select
                        options={masterUsertypeUserLists}
                        values={masterUsertypeUserLists?.filter(usertype_user => usertype_user.id === formData.reporting_id)}
                        name="reporting_id"
                        labelField={"name"}
                        valueField={"id"}
                        onChange={(selectedData) => selectHandler2(selectedData, "reporting_id")}
                        styles={customSelectStyles}
                      />
                      {errorsUser?.data?.reporting_id && (
                        <div className="invalid-feedback d-block">
                          <p className="text-danger">{errorsUser?.data?.reporting_id}</p>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                )}

                <Col lg={4}>
                  <FormInput
                    label={t("Name")}
                    type="text"
                    errors={errorsUser?.data}
                    value={formData.name}
                    name="name"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <FormInput
                    label={t("Mobile Number")}
                    type="text"
                    errors={errorsUser?.data}
                    value={formData.contact}
                    name="contact"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <FormInput
                    label={t("Email")}
                    type="text"
                    errors={errorsUser?.data}
                    value={formData.email}
                    name="email"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <FormInput
                    label={t("Address")}
                    type="text"
                    errors={errorsUser?.data}
                    value={formData.address}
                    name="address"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                {(!id) && (
                  <>
                    <Col lg={4}>
                      <FormInput
                        label={t("Username")}
                        type="text"
                        errors={errorsUser?.data}
                        value={formData.username}
                        name="username"
                        onChange={inputHandlerCaps}
                        containerClass={"mb-3"}
                      />
                    </Col>
                  </>
                )}

                {(!id) && (
                  <>
                    <Col lg={4}>
                      <FormInput
                        label={t("Password")}
                        type="text"
                        errors={errorsUser?.data}
                        value={formData.password}
                        name="password"
                        onChange={inputHandlerCaps}
                        containerClass={"mb-3"}
                      />
                    </Col>
                  </>
                )}

                

                
              </Row>

              <div className="d-flex justify-content-center">
                {loadingUser ? 
                  <Button color="primary" disabled>
                    <Spinner
                      className="spinner-border-sm me-1"
                      tag="span"
                      color="white"
                    />
                    Please wait ...
                  </Button>
                :
                  <Button type="button" onClick={handleSubmit} disabled={loadingUser}>
                    Submit
                  </Button>
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingMaster && (<Preloader/>)}
      {loadingUser && (<Preloader/>)}
    </>
  );
};

export default Dashboard;