import React, { useCallback, useEffect , useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button, Spinner, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { getTripReportList } from "../../redux/actions/VerifiedReportActions";
import { getMasterDriverList, getMasterVehicleList, getMasterVendorList} from "../../redux/actions/MasterActions";
import RoundedPagination from "../../Components/Paginate";
import FormInput from "../../Components/FormInput";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import {getDisplayDateTimeFormat} from '../../util/generalUtills'
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { CSVLink } from 'react-csv';
import DatePicker from "react-datepicker";
import { use } from "i18next";
import { addDays } from "date-fns";
import Preloader from "../../Components/Preloader";


const Dashboard = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const navigate=useNavigate();
  const {
    AuthReducer: { auth,errors,loading },
    verifiedReportReducer: {
      tripReportLists,
      loadingReport,
      errorsReport,
      reportCount,
    },
    masterReducer: {
      masterVehicleLists,
      masterDriverLists,
      masterVendorLists,
      loadingMaster,
      errorsMaster,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;
  const [vendorId, setVendorId] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [driverId, setDriverId] = useState("");
  const [validated, setValidated] = useState(false);

  useEffect(() => {
    if (auth?.token) {
      dispatch(getMasterVendorList(auth.token));
    }
  }, [auth?.token]);

  useEffect(()=>{
    dispatch(getMasterVehicleList(auth.token,vendorId));
    dispatch(getMasterDriverList(auth.token,vendorId));
  },[vendorId])


  const fetchTripReportList = useCallback(() => {
    if (auth?.token)
    {
      dispatch(getTripReportList(auth.token, paginate, page, search, sort,vendorId,vehicleId,driverId,startDate,endDate));
    }
  }, [auth?.token, paginate, page, search, sort, dispatch]);

  useDebounce(fetchTripReportList, 500, [auth, paginate, page, search, sort,vendorId,vehicleId,driverId,startDate,endDate]);

  const csvHead = [['SR No.(1)', 'Booking ID(2)', 'Pickup Address(3)', 'Drop Address(4)', 
    'Booking Date(5)', 'Name/Mobile(6)', 'Booking Status(7)', 'Tanker Number(8)', 
    'Remark(9)', 'Distance(10)', 'Amount(11)', 'Trip Time(12)']
  ]
  const csvData = tripReportLists.map((cv, index) => {
    const bookingLogs = cv?.bookingLogsBookingDetails || [];
    const waterSupplyStartLog = bookingLogs.find(log => log.status === 'ON GOING');
    const waterSupplyEndLog = bookingLogs.find(log => log.status === 'Water Supply End');
    const waterSupplyStart = waterSupplyStartLog ? getDisplayDateTimeFormat(waterSupplyStartLog.createdAt) : '';
    const waterSupplyEnd = waterSupplyEndLog ? getDisplayDateTimeFormat(waterSupplyEndLog.createdAt) : '';
    
    return [
      index + 1,
      cv?.bookingId,
      cv?.hydrantIdBookingDetailsHydrant?.address || '',
      cv?.destinationIdBookingDetailsDestination?.address || '',
      getDisplayDateTimeFormat(cv?.booking_date) || '',
      `${cv?.name || ''}/${cv?.mobile || ''}`,
      cv?.taxiStatus || '',
      cv?.vehicle_number || '',
      cv?.aen_remark || '',
      cv?.totalDistance || '',
      cv?.totalTripAmount || '',
      `${waterSupplyStart} || ${waterSupplyEnd}`
    ];
  });
  const exportData = csvHead.concat(csvData);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status === 401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
    
  },[errors])

  

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    dispatch(getTripReportList(auth.token, paginate, page, search, sort,vendorId,vehicleId,driverId,startDate,endDate));
  };

  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? reportCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const transformedVehicleOptions = useMemo(() => 
    masterVehicleLists.map(vehicle => ({
      ...vehicle,
      concatenatedLabel: `${vehicle.vehicle_number} - ${vehicle.vehicle_model}`
    })), 
    [masterVehicleLists]
  );

  const transformedDriverOptions = useMemo(() => 
    masterDriverLists.map(vehicle => ({
      ...vehicle,
      concatenatedLabel: `${vehicle.driver_id} - ${vehicle.driver_name}` 
    })), 
    [masterDriverLists]
  );

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      }
    }),
  };

  const printTable = () => {
    const printWindow = window.open('', '', 'height=600,width=800');
    printWindow.document.write('<html><head><style type="text/css">'+
      '#backgroundImage{'+
        'min-height: 50vh;'+
        'position: relative;'+
        'margin: 0;'+
        '-webkit-print-color-adjust: exact !important; '+
        'color-adjust: exact !important;'+
        '}'+
        '#backgroundImage:before {'+
        'content: "";'+
        'position: absolute;'+
        'z-index: -1;'+
        'top: 0;'+
        'bottom: 0;'+
        'left: 0;'+
        'right: 0;'+
        'opacity: 0.04;'+
        'background:'+
            'url(\'https://phedr.consoletrack.com/logo.jpg\')'+
        '}'+
        '</style></head><body onload="window.print()" id="backgroundImage"><div align="center ">'+document.title+'</div><div class="main1"><table border="1" cellspacing="0">');
    printWindow.document.write(document.getElementById('table-to-print').innerHTML);
    printWindow.document.write('</table></div><div align="center">This is a system generated report it does not require any signature or stamp</div><div align="right"><img src="logo.jpg" style="opacity: 0.1"></div></body></html>');
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Trip Report"), path: "/Trip-Report", active: true },
        ]}
        title={t("Trip Report")}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Vendor")}</Form.Label>
                    <Select
                      options={masterVendorLists}
                      values={masterVendorLists?.filter(name => name.id === vendorId)}
                      name="vendor_id"
                      labelField={"name"}
                      valueField={"id"}
                      onChange={(selectedData) => setVendorId(selectedData[0].id)}
                      styles={customSelectStyles}
                    />
                    
                    {errorsMaster?.data?.vendor_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsMaster?.data?.vendor_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Vehicle")}</Form.Label>
                    <Select
                      options={transformedVehicleOptions}
                      values={transformedVehicleOptions?.filter(option => option.id === vehicleId)}
                      name="vehicle_id"
                      labelField={"concatenatedLabel"}
                      valueField={"id"}
                      onChange={(selectedData) => setVehicleId(selectedData[0].id)}
                      styles={customSelectStyles}
                    />
                    
                    {errorsMaster?.data?.vehicle_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsMaster?.data?.vehicle_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Driver")}</Form.Label>
                    <Select
                      options={transformedDriverOptions}
                      values={transformedDriverOptions?.filter(option => option.id === vehicleId)}
                      name="driver_id"
                      labelField={"concatenatedLabel"}
                      valueField={"id"}
                      onChange={(selectedData) => setDriverId(selectedData[0].id)}
                      styles={customSelectStyles}
                    />
                    
                    {errorsMaster?.data?.vehicle_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsMaster?.data?.vehicle_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Date")}</Form.Label>
                    <DatePicker
                      selectsRange={true}
                      dateFormat="yyyy-MM-dd"
                      startDate={startDate}
                      endDate={endDate}
                      maxDate={addDays(new Date(),1)}
                      todayButton="Today"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      className="form-control"
                      onChange={(update) => {
                        setDateRange(update);
                      }}
                      withPortal
                    />
                    {errorsMaster?.data?.startDate && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsMaster?.data?.startDate}</p>
                      </div>
                    )}
                    {errorsMaster?.data?.endDate && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsMaster?.data?.endDate}</p>
                      </div>
                    )}
                  </Form.Group>
                  
                </Col>

                <Col lg={2} >
                  <div className="mb-3" style={{ marginTop: '1.6rem' }}>
                    {loadingReport ? 
                      <Button color="primary" disabled>
                        <Spinner
                          className="spinner-border-sm me-1"
                          tag="span"
                          color="white"
                        />
                        Please wait ...
                      </Button>
                    :
                      <Button type="button" onClick={handleSubmit} disabled={loadingReport}>
                        Submit
                      </Button>
                    }
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                  <Col lg={3}>
                    <div className="d-flex align-items-center">
                      <input
                        type="search"
                        placeholder="Search..."
                        className="form-control ms-1"
                        autoComplete="off"
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </Col>
                  <Col lg={9}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="button-list">
                        <Button variant="dark" onClick={printTable}>
                          <i className="mdi mdi-printer me-1"></i>Print
                        </Button>
                        <CSVLink
                          filename="trip-reports.csv"
                          data={exportData}
                          className="btn btn-info text-white ms-1"
                          title="Export To CSV"
                        >
                          <i className="mdi mdi-file-excel me-1"></i>
                          Excel
                        </CSVLink>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div >
                <Table className="mb-0 table-bordered" responsive size="sm" striped id="table-to-print">
                  <thead>
                    <tr>
                      <th>SR No.(1)</th>
                      <th>Booking ID(2)</th>
                      <th>Pickup Address(3)</th>
                      <th>Drop Address(4)</th>
                      <th>Booking Date(5)</th>
                      <th>Name/Mobile(6)</th>
                      <th>Booking Status(7)</th>
                      <th>Tanker Number(8)</th>
                      <th>Remark(9)</th>
                      <th>Distance(10)</th>
                      <th>Amount(11)</th>
                      <th>Trip Time(12)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tripReportLists.length === 0 ? (
                      <tr>
                        <td colSpan="13">No data found</td>
                      </tr>
                    ) : (
                      tripReportLists?.map?.((item, index) => {
                        const bookingLogs = item?.bookingLogsBookingDetails || [];
                        const waterSupplyStartLog = bookingLogs.find(log => log.status === 'ON GOING');
                        const waterSupplyEndLog = bookingLogs.find(log => log.status === 'Water Supply End');
                        const waterSupplyStart = waterSupplyStartLog ? getDisplayDateTimeFormat(waterSupplyStartLog.createdAt) : '';
                        const waterSupplyEnd = waterSupplyEndLog ? getDisplayDateTimeFormat(waterSupplyEndLog.createdAt) : '';
                        return (
                        <tr key={item.id}>
                          <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                          <td>{item?.bookingId}</td>
                          <td>{item?.hydrantIdBookingDetailsHydrant?.address}</td>
                          <td>{item?.destinationIdBookingDetailsDestination?.address}</td>
                          <td>{getDisplayDateTimeFormat(item?.booking_date)}</td>
                          <td>{item?.name}<br/>{item?.mobile}</td>
                          <td>{item?.taxiStatus}</td>
                          <td>{item?.vehicle_number}</td>
                          <td>{item?.aen_remark}</td>
                          <td>{item?.totalDistance}</td>
                          <td>{item?.totalTripAmount}</td>
                          <td>{waterSupplyStart}<br/>{waterSupplyEnd}</td>
                        </tr>
                      )})
                    )}
                  </tbody>
                </Table>
              </div>

              <div className="mt-3 d-lg-flex align-items-center text-center pb-1">
                <div className="d-inline-block me-3">
                  <label className="me-1">Display :</label>
                  <select className="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span className="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(reportCount / paginate)}</strong> </span>
                <RoundedPagination
                  currentPage={page}
                  totalPages={Math.ceil(reportCount / paginate)}
                  onPageChange={(pageNumber) => setPage(pageNumber)}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingMaster && (<Preloader/>)}
      {loadingReport && (<Preloader/>)}
    </>
  );
};

export default Dashboard;
