const initialState = {
    fareLists: [],
    fareStatus: [],
    changedFareStatus : [],
    loadingFare: false,
    errorsFare: {}
}

const  fareMasterReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_FARE_LIST_PENDING":
        case "ADD_FARE_PENDING":
        case "UPDATE_FARE_PENDING":
        case "DELETE_FARE_PENDING":
        case "GET_FARE_LISTBYID_PENDING":
            return { ...state, loadingFare: true };

        case "GET_FARE_LIST_FULFILLED":
            return {
                ...state,
                loadingFare: false,
                fareLists: action.payload,
                fareCount: action.fareCount
            };

        case "ADD_FARE_FULFILLED":
            return {
                ...state,
                loadingFare: false,
                changedFareStatus: action.payload,
            };

        case "UPDATE_FARE_FULFILLED":
            return {
                ...state,
                loadingFare: false,
                changedFareStatus: action.payload,
            };

        case "GET_FARE_LISTBYID_FULFILLED":
            return {
                ...state,
                loadingFare: false,
                fareLists: action.payload
            };

        case "DELETE_FARE_FULFILLED":
            return {
                ...state,
                loadingFare: false,
                changedFareStatus: action.payload
            };

        case "RESET_DELETE_FARE_STATUS":
            return {
                ...state,
                loadingFare: false,
                changedFareStatus: [],
                errorsFare:{}
            };

        case "GET_FARE_LIST_REJECTED":
        case "ADD_FARE_REJECTED":
        case "UPDATE_FARE_REJECTED":
        case "DELETE_FARE_REJECTED":
        case "GET_FARE_LISTBYID_REJECTED":
            return {
                ...state,
                loadingFare: false,
                errorsFare: action.payload
            };

        default:
            return state;
    }
}

export default fareMasterReducer;