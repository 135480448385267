import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./layouts/Layout";
import Dashboard from "./pages/Dashboard/Dashboard";

import VehicleIndex from "./pages/Vehicle/Index";
import VehicleAdd from "./pages/Vehicle/Add";

import StateIndex from "./pages/State/Index";
import StateAdd from "./pages/State/Add";

import GenerateSlipForm from "./pages/Booking/GenerateSlip";
import GenerateSlip from "./pages/Booking/GenerateSlipList";
import BookingAdd from "./pages/Booking/Add";
import ScheduleBooking from "./pages/Booking/ScheduleBooking";
import CurrentBooking from "./pages/Booking/CurrentBooking";
import CancelBooking from "./pages/Booking/CancelBooking";
import PendingBooking from "./pages/Booking/PendingBooking";
import CompleteBooking from "./pages/Booking/CompleteBooking";
import AssignVehicleBooking from "./pages/Booking/AssignBooking";

import CityIndex from "./pages/City/Index";
import CityAdd from "./pages/City/Add";

import UserIndex from "./pages/User/Index";
import UserAdd from "./pages/User/Add";


import VendorIndex from "./pages/Vendor/Index";
import VendorAdd from "./pages/Vendor/Add";


import DestinationIndex from "./pages/Destination/Index";
import DestinationAdd from "./pages/Destination/Add";

import DriverIndex from "./pages/Driver/Index";
import DriverAdd from "./pages/Driver/Add";

import UsertypeIndex from "./pages/Usertype/Index";
import UsertypeAdd from "./pages/Usertype/Add";

import HydrantIndex from "./pages/Hydrant/Index";
import HydrantAdd from "./pages/Hydrant/Add";

import FareIndex from "./pages/Fare/Index";
import FareAdd from "./pages/Fare/Add";

import GeneralSettingAdd from "./pages/General Setting/Add";
import GeneralSettingAddSubmit from "./pages/General Setting/Add";

import TripReport from "./pages/Verified Report/tripReport";
import RevenueReport from "./pages/Verified Report/revenueReport";
import MonthlyRevenueReport from "./pages/Verified Report/monthlyRevenueReport";

import ProvisionalTripReport from "./pages/Provisional Report/provisionalTripReport";
import ProvisionalRevenueReport from "./pages/Provisional Report/provisionalRevenueReport";
import ProvisionalMonthlyRevenueReport from "./pages/Provisional Report/provisionalMonthlyRevenueReport";


import MapLiveTracking from "./pages/Map/LiveTrack";
import MapHistoryTracking from "./pages/Map/HistoryTrack";

import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import { ToastContainer } from "react-toastify";

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route element={<Layout />}>
          <Route path="/" element={<Dashboard />} />

          <Route path="/Users" element={<UserIndex />} />
          <Route path="/Add-User" element={<UserAdd />} />
          <Route path="/Update-User/:id" element={<UserAdd />} />

          <Route path="/Vehicle" element={<VehicleIndex />} />
          <Route path="/Add-Vehicle" element={<VehicleAdd />} />
          <Route path="/Update-Vehicle/:id" element={<VehicleAdd />} />


          <Route path="/State" element={<StateIndex />} />
          <Route path="/Add-State" element={<StateAdd />} />
          <Route path="/Update-State/:id" element={<StateAdd />} />

          <Route path="/City" element={<CityIndex />} />
          <Route path="/Add-City" element={<CityAdd />} />
          <Route path="/Update-City/:id" element={<CityAdd />} />

          <Route path="/Vendor" element={<VendorIndex />} />
          <Route path="/Add-Vendor" element={<VendorAdd />} />
          <Route path="/Update-Vendor/:id" element={<VendorAdd />} />

          <Route path="/Destination" element={<DestinationIndex />} />
          <Route path="/Add-Destination" element={<DestinationAdd />} />
          <Route path="/Update-Destination/:id" element={<DestinationAdd />} />

          <Route path="/Driver" element={<DriverIndex />} />
          <Route path="/Add-Driver" element={<DriverAdd />} />
          <Route path="/Update-Driver/:id" element={<DriverAdd />} />

          <Route path="/Usertype" element={<UsertypeIndex />} />
          <Route path="/Add-Usertype" element={<UsertypeAdd />} />
          <Route path="/Update-Usertype/:id" element={<UsertypeAdd />} />

          <Route path="/Hydrant" element={<HydrantIndex />} />
          <Route path="/Add-Hydrant" element={<HydrantAdd />} />
          <Route path="/Update-Hydrant/:id" element={<HydrantAdd />} />

          <Route path="/Fare-Master" element={<FareIndex />} />
          <Route path="/Add-Fare-Master" element={<FareAdd />} />
          <Route path="/Update-Fare-Master/:id" element={<FareAdd />} />

          <Route path="/General-Settings" element={<GeneralSettingAdd />} />
          <Route path="/General-Settings" element={<GeneralSettingAddSubmit />} />
          
          <Route path="/Generate-Slip" element={<GenerateSlip />} />
          <Route path="/Generate-Slip/:id" element={<GenerateSlipForm />} />
          <Route path="/Add-Booking" element={<BookingAdd />} />
          <Route path="/Schedule-Booking" element={<ScheduleBooking />} />
          <Route path="/Current-Booking" element={<CurrentBooking />} />
          <Route path="/Cancel-Booking" element={<CancelBooking />} />
          <Route path="/Pending-Booking" element={<PendingBooking />} />
          <Route path="/Complete-Booking" element={<CompleteBooking />} />
          <Route path="/Assign-Vehicle-Booking/:id" element={<AssignVehicleBooking />} />

          <Route path="/Trip-Report" element={<TripReport />} />
          <Route path="/Revenue-Report" element={<RevenueReport />} />
          <Route path="/Monthly-Revenue-Report" element={<MonthlyRevenueReport />} />

          <Route path="/Provisional-Trip-Report" element={<ProvisionalTripReport />} />
          <Route path="/Provisional-Revenue-Report" element={<ProvisionalRevenueReport />} />
          <Route path="/Provisional-Monthly-Revenue-Report" element={<ProvisionalMonthlyRevenueReport />} />

          <Route path="/Live-Tracking" element={<MapLiveTracking />} />
          <Route path="/History-Tracking" element={<MapHistoryTracking />} />


        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
