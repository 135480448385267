import React from "react";
import { Row, Col, Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

/**
 * PageTitle component
 */
const PageTitle = (props) => {
  return (
    <Row>
      <Col>
        <div className="page-title-box">
          <div className="page-title-right">
            <Breadcrumb className="m-0">
            <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>

              {(props.breadCrumbItems || []).map((item, index) => {
                return item.active ? (
                  <Breadcrumb.Item active key={index}>
                    {item.label}
                  </Breadcrumb.Item>
                ) : (
                  <>
                  
                  <Breadcrumb.Item key={index}>
                  <Link to={`/${item.path}`}>
                    {item.label}
                    </Link>
                  </Breadcrumb.Item>
                  
                  </>
                  
                );
              })}
            </Breadcrumb>
          </div>
          <h4 className="page-title">{props.title}</h4>
        </div>
      </Col>
    </Row>
  );
};

export default PageTitle;
