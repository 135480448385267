import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { getFareListById, saveFareData, updateFareData } from "../../redux/actions/FareMasterActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import swal from 'sweetalert2';
import Preloader from "../../Components/Preloader";

const Dashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    fareMasterReducer: {
      fareLists,
      loadingFare,
      errorsFare,
      changedFareStatus,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if(id)
    {
      dispatch(getFareListById(auth?.token, id));
    }
  }, [auth, id]);
  
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(id)
    {
      dispatch(updateFareData(auth?.token, formData,id));
    }
    else{
      dispatch(saveFareData(auth?.token, formData));
    }
  };

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(() => {
    if (errorsFare && Object.keys(errorsFare).length > 0) {
      if (errorsFare?.status === 409) {
        swal.fire({
          show: true,
          title: "Oops!",
          text: errorsFare?.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
        dispatch({ type: 'RESET_DELETE_FARE_STATUS' });
      } else if (errorsFare?.status === 400) {
        // Handle other status
      } else {
        dispatch({ type: 'RESET_DELETE_FARE_STATUS' });
      }
    }
  }, [errorsFare, dispatch]);

  useEffect(() => {
    if (changedFareStatus?.success) {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedFareStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_FARE_STATUS' });
      navigate(-1);
    }
  }, [changedFareStatus, dispatch, navigate]);

  useEffect(()=>{
    if(id)
    {
      if(fareLists && Object.keys(fareLists).length>0)
      {
        setFormData({
          "rate_type": fareLists?.rate_type,
          "rate_day": fareLists?.rate_day,
          "rate_night": fareLists?.rate_night,
          "min_km": fareLists?.min_km,
          "max_km": fareLists?.max_km
        });
      }
    }
  },[fareLists]);

  useEffect(() => {
    setFormData({});
    dispatch({ type: 'RESET_DELETE_FARE_STATUS' });
  }, [dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      }
    }),
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Fare Master"), path: "Fare-Master" },
          { label: t("Add Fare Master"), path: "/Add-Fare-Master", active: true },
        ]}
        title={t("Add Fare Master")}
      />
      <Row>
        <Col lg={3}></Col>
        <Col lg={6}>
          <Card>
            <Card.Body>
              {errorsFare?.data?.message && (
                <div className="alert alert-danger">
                  {errorsFare.data.message}
                </div>
              )}
              <Row>
                <Col lg={6}>
                  <FormInput
                    label={t("Rate Type")}
                    type="text"
                    errors={errorsFare?.data}
                    value={formData.rate_type}
                    name="rate_type"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={6}>
                  <FormInput
                    label={t("Day Rate")}
                    type="text"
                    errors={errorsFare?.data}
                    value={formData.rate_day}
                    name="rate_day"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={6}>
                  <FormInput
                    label={t("Night Rate")}
                    type="text"
                    errors={errorsFare?.data}
                    value={formData.rate_night}
                    name="rate_night"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={6}>
                  <FormInput
                    label={t("Minimum Km")}
                    type="text"
                    errors={errorsFare?.data}
                    value={formData.min_km}
                    name="min_km"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>
              
                <Col lg={6}>
                  <FormInput
                    label={t("Maximum Km")}
                    type="text"
                    errors={errorsFare?.data}
                    value={formData.max_km}
                    name="max_km"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>
              </Row>
              
              <div className="d-flex justify-content-center">
                {loading ? 
                  <Button color="primary" disabled>
                    <Spinner
                      className="spinner-border-sm me-1"
                      tag="span"
                      color="white"
                    />
                    Please wait ...
                  </Button>
                :
                  <Button type="button" onClick={handleSubmit} disabled={loading}>
                    Submit
                  </Button>
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingFare && (<Preloader/>)}
    </>
  );
};

export default Dashboard;