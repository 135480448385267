import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { getHydrantListById, saveHydrantData, updateHydrantData } from "../../redux/actions/HydrantActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import LeafletSingleMap from "../../Components/LeafletSingleMap";
import { getAddressByLatLng, getAllUsertypeUserList, getMasterVehicleList, getMasterVendorList } from "../../redux/actions/MasterActions";
import Preloader from "../../Components/Preloader";
import { getHistoryTracking, getLiveTracking } from "../../redux/actions/MapActions";
import LeafletMapAllVehicle from "../../Components/LeafletMapAllVehicle";
import LeafletMapHistoryVehicle from "../../Components/LeafletMapHistoryVehicle";
import HyperDatepicker from "../../Components/Datepicker";
import DatePicker from "react-datepicker";
import { addDays, subDays } from "date-fns";
import { format, setHours, setMinutes, setSeconds, setMilliseconds } from 'date-fns';



const HistoryTrack = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [vendorId, setVendorId] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [validated, setValidated] = useState(false);
  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0); // Sets time to 00:00:00.000
    return date;
  });
  
  const [endTime, setEndTime] = useState(new Date());
  const [historyTracking, setHistoryTracking] = useState([]);

  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    masterReducer: {
      masterVehicleLists,
      masterDriverLists,
      masterVendorLists,
      loadingMaster,
      errorsMaster,
    },
    mapReducer: {
      historyTrackingData,
      vehicleCount,
      loadingMap,
      errorsMap,
    }
  } = useSelector((state) => state);


  useEffect(() => {
    dispatch(getMasterVendorList(auth.token));
  }, [auth]);

  useEffect(() => {
    dispatch(getMasterVehicleList(auth.token, vendorId));
  }, [vendorId]);

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(() => {
    console.log('historyTrackingData', historyTrackingData);
    setHistoryTracking(historyTrackingData)
  }, [historyTrackingData]);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    const selectedDate = new Date(date);

    // Set startTime to have the same date as selectedDate but the time from the selected startTime
    const finalStartTime = setMilliseconds(
      setSeconds(
        setMinutes(
          setHours(new Date(selectedDate), startTime.getHours()), // Set hours
          startTime.getMinutes() // Set minutes
        ),
        startTime.getSeconds() // Set seconds
      ),
      startTime.getMilliseconds() // Set milliseconds
    );

    // Set endTime to have the same date as selectedDate but the time from the selected endTime
    const finalEndTime = setMilliseconds(
      setSeconds(
        setMinutes(
          setHours(new Date(selectedDate), endTime.getHours()), // Set hours
          endTime.getMinutes() // Set minutes
        ),
        endTime.getSeconds() // Set seconds
      ),
      endTime.getMilliseconds() // Set milliseconds
    );

    // Format the date and times properly
    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
    const formattedStartTime = finalStartTime.toISOString(); // Ensure it's the correct ISO format
    const formattedEndTime = finalEndTime.toISOString(); // Ensure it's the correct ISO format

    // Fetch the history tracking data
    dispatch(getHistoryTracking(auth.token, "", formattedDate, formattedStartTime, formattedEndTime, vehicleId, "", ""));
  };

  // Handler for reloading the data
  const handleReload = () => {
    const selectedDate = new Date(date);

    // Set startTime to have the same date as selectedDate but the time from the selected startTime
    const finalStartTime = setMilliseconds(
      setSeconds(
        setMinutes(
          setHours(new Date(selectedDate), startTime.getHours()), // Set hours
          startTime.getMinutes() // Set minutes
        ),
        startTime.getSeconds() // Set seconds
      ),
      startTime.getMilliseconds() // Set milliseconds
    );

    // Set endTime to have the same date as selectedDate but the time from the selected endTime
    const finalEndTime = setMilliseconds(
      setSeconds(
        setMinutes(
          setHours(new Date(selectedDate), endTime.getHours()), // Set hours
          endTime.getMinutes() // Set minutes
        ),
        endTime.getSeconds() // Set seconds
      ),
      endTime.getMilliseconds() // Set milliseconds
    );

    // Format the date and times properly
    const formattedDate = format(selectedDate, 'yyyy-MM-dd');
    const formattedStartTime = finalStartTime.toISOString();
    const formattedEndTime = finalEndTime.toISOString();

    // Re-fetch the history tracking data
    dispatch(getHistoryTracking(auth.token, "", formattedDate, formattedStartTime, formattedEndTime, vehicleId, "", ""));
  };

  const transformedVehicleOptions = useMemo(() =>
    masterVehicleLists.map(vehicle => ({
      ...vehicle,
      concatenatedLabel: `${vehicle.vehicle_number} - ${vehicle.vehicle_model}`
    })),
    [masterVehicleLists]
  );

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      },
      zIndex: 1, // Ensures the control part doesn't have z-index issues
    }),
    menu: (base) => ({
      ...base,
      zIndex: 1000, // Ensures dropdown appears on top of other elements
    }),
    menuPortal: (base) => ({
      ...base,
      zIndex: 1000, // Ensures the portal is rendered on top if you're using menuPortalTarget
    }),
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("History Tracking"), path: "/History-Tracking", active: true },
        ]}
        title={t("History Tracking")}
      />
      <Row>
        <Col lg={12}>
          {historyTracking.length === 0 ? (
            <>
              <Card>
                <Card.Body>
                  <Row>
                    <Col lg={2}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Vendor")}</Form.Label>
                        <Select
                          options={masterVendorLists}
                          values={masterVendorLists?.filter(name => name.id === vendorId)}
                          name="vendor_id"
                          labelField={"name"}
                          valueField={"id"}
                          searchable={true}
                          searchBy="name" // Adjusted this to match the label field
                          dropdownHeight="300px"
                          separator={true}
                          onChange={(selectedData) => setVendorId(selectedData[0].id)}
                          styles={customSelectStyles}
                        />
                        {errorsMaster?.data?.vendor_id && (
                          <div className="invalid-feedback d-block">
                            <p className="text-danger">{errorsMaster?.data?.vendor_id}</p>
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col lg={2}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Vehicle")}</Form.Label>
                        <Select
                          options={transformedVehicleOptions}
                          values={transformedVehicleOptions?.filter(option => option.id === vehicleId)}
                          name="vehicle_id"
                          labelField="concatenatedLabel"
                          valueField="id"
                          searchable={true}
                          searchBy="concatenatedLabel" // Adjusted this to match the label field
                          dropdownHeight="300px"
                          separator={true}
                          onChange={(selectedData) => setVehicleId(selectedData[0].id)}
                          styles={customSelectStyles}
                        />

                        {errorsMaster?.data?.vehicle_id && (
                          <div className="invalid-feedback d-block">
                            <p className="text-danger">{errorsMaster?.data?.vehicle_id}</p>
                          </div>
                        )}
                      </Form.Group>
                    </Col>

                    <Col lg={2}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("Tracking Date")}</Form.Label>
                        <DatePicker
                          selectsRange={false}
                          dateFormat="yyyy-MM-dd"
                          startDate={date}
                          selected={date}
                          minDate={subDays(new Date(), 30)}
                          maxDate={addDays(new Date(), 0)}
                          className="form-control"
                          onChange={(update) => {
                            setDate(update);
                            setStartTime(null); // Reset start time when date changes
                            setEndTime(null);   // Reset end time when date changes
                          }}
                          withPortal
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={2}>
                      <Form.Group className="mb-3" style={{ position: 'relative', display:'grid' }}>
                        <Form.Label>{t("Tracking Start Time")}</Form.Label>
                        <DatePicker
                          selected={startTime}
                          onChange={time => setStartTime(time)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeFormat="HH:mm:ss"
                          dateFormat="HH:mm:ss"
                          className="form-control"
                          style={{width:'100%'}}
                          minTime={new Date(date.setHours(0, 0))}
                          maxTime={endTime || new Date(date.setHours(23, 59))}
                          placeholderText="Start Time"
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={2}>
                      <Form.Group className="mb-3" style={{ position: 'relative',display:'grid' }}>
                        <Form.Label>{t("Tracking End Time")}</Form.Label>
                        <DatePicker
                          selected={endTime}
                          onChange={time => setEndTime(time)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeFormat="HH:mm:ss"
                          dateFormat="HH:mm:ss"
                          className="form-control"
                          minTime={startTime || new Date(date.setHours(0, 0))}
                          maxTime={new Date(date.setHours(23, 59))}
                          placeholderText="End Time"
                        />
                      </Form.Group>
                    </Col>

                    <Col lg={2} style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                      <div className="mb-3" style={{ marginTop: '1.6rem' }}>
                        {loadingMap ? (
                          <Button color="primary" disabled>
                            <Spinner
                              className="spinner-border-sm me-1"
                              tag="span"
                              color="white"
                            />
                            Please wait ...
                          </Button>
                        ) : (
                          <Button type="button" onClick={handleSubmit} disabled={loadingMap}>
                            Submit
                          </Button>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </>
          ) : (
            <>
              <Card>
                <Card.Body>
                  <div >
                    <LeafletMapHistoryVehicle
                      mapHeight={"600px"}
                      routeData={historyTracking}
                      isMarkerShow={true}
                      handleNewSearch={() => setHistoryTracking([])}
                    />
                  </div>
                </Card.Body>
              </Card>
            </>
          )}
        </Col>
      </Row>
      {loadingMap && <Preloader />}
    </>
  );
};

export default HistoryTrack;
