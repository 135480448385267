import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { saveGeneralSettingData, getGeneralSettingList } from "../../redux/actions/GeneralSettingActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import {getDateTimeMysqlGivenFormatYmd } from '../../util/generalUtills';
import Preloader from "../../Components/Preloader";

const Dashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    generalSettingReducer: {
      generalSettingLists,
      loadingGeneralSetting,
      errorsGeneralSetting,
      changedGeneralSettingStatus,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    dispatch(getGeneralSettingList(auth?.token, id));
  }, [auth, id]);


  const options = [
    { value: 'Enable', label: 'Enable', isDisabled: false },
    { value: 'Disable', label: 'Disable', isDisabled: false },
  ];

  const options2 = [
    { value: 'SMS', label: 'SMS', isDisabled: false },
    { value: 'Whatsapp', label: 'Whatsapp', isDisabled: false },
  ];

  const options3 = [
    { value: 'Image', label: 'Image', isDisabled: false },
    { value: 'Video', label: 'Video', isDisabled: false },
  ];

  const options4 = [
    { value: 'Google', label: 'Google', isDisabled: false },
    { value: 'Mapbox', label: 'Mapbox', isDisabled: false },
  ];

  
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    dispatch(saveGeneralSettingData(auth?.token, formData));
  };

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(() => {
    if (errorsGeneralSetting && Object.keys(errorsGeneralSetting).length > 0)
    {
      if (errorsGeneralSetting?.status === 409)
      {
        swal.fire({
          show: true,
          title: "Oops!",
          text: errorsGeneralSetting?.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
        dispatch({ type: 'RESET_DELETE_GENERAL_SETTING_STATUS' });
      }
      else if (errorsGeneralSetting?.status === 400)
      {
        // Handle other status
      }
      else
      {
        dispatch({ type: 'RESET_DELETE_GENERAL_SETTING_STATUS' });
      }
    }
  }, [errorsGeneralSetting, dispatch]);

  useEffect(() => {
    if(generalSettingLists && Object.keys(generalSettingLists).length>0)
    {
      setFormData({
        company_name: generalSettingLists?.company_name,
        title: generalSettingLists?.title,
        url: generalSettingLists?.url,
        app_url: generalSettingLists?.app_url,
        background: generalSettingLists?.background,
        home_url: generalSettingLists?.home_url,
        image_time: generalSettingLists?.image_time,
        bill_prefix: generalSettingLists?.bill_prefix,
        web_booking_prefix: generalSettingLists?.web_booking_prefix ,
        app_booking_prefix: generalSettingLists?.app_booking_prefix ,
        map_api: generalSettingLists?.map_api,
        map_type: generalSettingLists?.map_type,
        default_sms: generalSettingLists?.default_sms,
        sent_complete_sms: generalSettingLists?.sent_complete_sms,
        change_booking_date: generalSettingLists?.change_booking_date,
        normal_customer: generalSettingLists?.normal_customer,
        special_customer: generalSettingLists?.special_customer,
        vip_customer: generalSettingLists?.vip_customer,
        vvip_customer: generalSettingLists?.vvip_customer,
        customer_complete: generalSettingLists?.customer_complete,
        missed_call_number: generalSettingLists?.missed_call_number,
        no_of_tanker: generalSettingLists?.no_of_tanker,
        block_duplicate_booking: generalSettingLists?.block_duplicate_booking ,
        blacklist_status: generalSettingLists?.blacklist_status ,
        location_interval: generalSettingLists?.location_interval,
        res_type: generalSettingLists?.res_type,
        update_url: generalSettingLists?.update_url,
        voice_notification: generalSettingLists?.voice_notification,
        nfc_reader_hydrant: generalSettingLists?.nfc_reader_hydrant,
        nfc_reader_destination: generalSettingLists?.nfc_reader_destination,
        video_recording_time: generalSettingLists?.video_recording_time,
        resend_otp: generalSettingLists?.resend_otp,
        reject_gps_confirmation: generalSettingLists?.reject_gps_confirmation,
        reject_internet_confirmation: generalSettingLists?.reject_internet_confirmation,
        reject_booking_gps_off: generalSettingLists?.reject_booking_gps_off,
        reject_booking_internet_off: generalSettingLists?.reject_booking_internet_off ,
        booking_approval: generalSettingLists?.booking_approval ,
        aen_jen_approval: generalSettingLists?.aen_jen_approval,
        send_remark_mail: generalSettingLists?.send_remark_mail,
        get_otp_via_misscall: generalSettingLists?.get_otp_via_misscall,
        aen_jen_otp_via: generalSettingLists?.aen_jen_otp_via,
        aen_jen_provisional_via: generalSettingLists?.aen_jen_provisional_via,
        journay_distance_calculation: generalSettingLists?.journay_distance_calculation,
      });
    }
  }, [generalSettingLists]);

  useEffect(() => {
    if (changedGeneralSettingStatus?.success) {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedGeneralSettingStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_GENERAL_SETTING_STATUS' });
      dispatch(getGeneralSettingList(auth?.token, id));
    }
  }, [changedGeneralSettingStatus, dispatch, navigate]);

  useEffect(() => {
    setFormData({});
    dispatch({ type: 'RESET_DELETE_GENERAL_SETTING_STATUS' });
  }, [dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectHandler = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData[0].value,
    }));
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      }
    }),
  };

  const onDateChange = (name,value) => {
    if (value) {
      setFormData({ ...formData, [name]: getDateTimeMysqlGivenFormatYmd(value) });
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("General Setting"), path: "General Setting" },
        ]}
        title={t("General Setting")}
      />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              {errorsGeneralSetting?.data?.message && (
                <div className="alert alert-danger">
                  {errorsGeneralSetting?.data?.message}
                </div>
              )}

              <Row>
                <Col lg={3}>
                  <FormInput
                    label={t("Company Name")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.company_name}
                    name="company_name"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Title")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.title}
                    name="title"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Web Url")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.url}
                    name="url"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("App Url")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.app_url}
                    name="app_url"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Home Url")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.home_url}
                    name="home_url"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Image time")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.image_time}
                    name="image_time"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Bill Prefix")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.bill_prefix}
                    name="bill_prefix"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Web Booking Prefix")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.web_booking_prefix}
                    name="web_booking_prefix"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("App Booking Prefix")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.app_booking_prefix}
                    name="app_booking_prefix"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Map Api")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.map_api}
                    name="map_api"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Map Type")}</Form.Label>
                    <Select
                      options={options4}
                      values={options4.filter((opt) => opt.value === formData.map_type)}
                      name="map_type"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "map_type")}
                      styles={customSelectStyles}
                    />
                    
                    {errorsGeneralSetting?.data?.map_type && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.map_type}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Default SMS")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.default_sms}
                    name="default_sms"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Sent Complete SMS")}</Form.Label>
                    <Select
                      options={options}
                      values={options.filter((opt) => opt.value === formData.sent_complete_sms)}
                      name="sent_complete_sms"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "sent_complete_sms")}
                      styles={customSelectStyles}
                    />
                    
                    {errorsGeneralSetting?.data?.sent_complete_sms && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.sent_complete_sms}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Change Booking Date")}</Form.Label>
                    <Select
                      options={options}
                      values={options.filter((opt) => opt.value === formData.change_booking_date)}
                      name="change_booking_date"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "change_booking_date")}
                      styles={customSelectStyles}
                    />
                    
                    {errorsGeneralSetting?.data?.change_booking_date && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.change_booking_date}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Normal Customer")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.normal_customer}
                    name="normal_customer"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Special Customer")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.special_customer}
                    name="special_customer"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("VIP Customer")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.vip_customer}
                    name="vip_customer"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("VVIP Customer")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.vvip_customer}
                    name="vvip_customer"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Customer Complete")}</Form.Label>
                    <Select
                      options={options}
                      values={options.filter((opt) => opt.value === formData.customer_complete)}
                      name="customer_complete"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "customer_complete")}
                      styles={customSelectStyles}
                    />
                    {errorsGeneralSetting?.data?.customer_complete && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.customer_complete}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Missed Call Number")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.missed_call_number}
                    name="missed_call_number"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("No. Of Tanker")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.no_of_tanker}
                    name="no_of_tanker"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Block Duplicate Booking")}</Form.Label>
                    <Select
                      options={options}
                      values={options.filter((opt) => opt.value === formData.block_duplicate_booking)}
                      name="block_duplicate_booking"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "block_duplicate_booking")}
                      styles={customSelectStyles}
                    />
                    {errorsGeneralSetting?.data?.block_duplicate_booking && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.block_duplicate_booking}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Blacklist Status")}</Form.Label>
                    <Select
                      options={options}
                      values={options.filter((opt) => opt.value === formData.blacklist_status)}
                      name="blacklist_status"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "blacklist_status")}
                      styles={customSelectStyles}
                    />
                    {errorsGeneralSetting?.data?.blacklist_status && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.blacklist_status}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Location Interval")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.location_interval}
                    name="location_interval"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Destination Type")}</Form.Label>
                    <Select
                      options={options3}
                      values={options3.filter((opt) => opt.value === formData.res_type)}
                      name="res_type"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "res_type")}
                      styles={customSelectStyles}
                    />
                    
                    {errorsGeneralSetting?.data?.res_type && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.res_type}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Update Url")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.update_url}
                    name="update_url"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Voice Notification")}</Form.Label>
                    <Select
                      options={options}
                      values={options.filter((opt) => opt.value === formData.voice_notification)}
                      name="voice_notification"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "voice_notification")}
                      styles={customSelectStyles}
                    />
                    {errorsGeneralSetting?.data?.voice_notification && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.voice_notification}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("NFC Reader Hydrant")}</Form.Label>
                    <Select
                      options={options}
                      values={options.filter((opt) => opt.value === formData.nfc_reader_hydrant)}
                      name="nfc_reader_hydrant"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "nfc_reader_hydrant")}
                      styles={customSelectStyles}
                    />
                    {errorsGeneralSetting?.data?.nfc_reader_hydrant && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.nfc_reader_hydrant}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("NFC Reader Destination")}</Form.Label>
                    <Select
                      options={options}
                      values={options.filter((opt) => opt.value === formData.nfc_reader_destination)}
                      name="nfc_reader_destination"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "nfc_reader_destination")}
                      styles={customSelectStyles}
                    />
                    {errorsGeneralSetting?.data?.nfc_reader_destination && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.nfc_reader_destination}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Video Recording Time")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.video_recording_time}
                    name="video_recording_time"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Resend Otp")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.resend_otp}
                    name="resend_otp"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Reject Booking GPS Off Confirmation")}</Form.Label>
                    <Select
                      options={options}
                      values={options.filter((opt) => opt.value === formData.reject_gps_confirmation)}
                      name="reject_gps_confirmation"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "reject_gps_confirmation")}
                      styles={customSelectStyles}
                    />
                    {errorsGeneralSetting?.data?.reject_gps_confirmation && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.reject_gps_confirmation}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Reject Booking Internet Off Confirmation")}</Form.Label>
                    <Select
                      options={options}
                      values={options.filter((opt) => opt.value === formData.reject_internet_confirmation)}
                      name="reject_internet_confirmation"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "reject_internet_confirmation")}
                      styles={customSelectStyles}
                    />
                    {errorsGeneralSetting?.data?.reject_internet_confirmation && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.reject_internet_confirmation}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Reject Booking GPS Off")}</Form.Label>
                    <Select
                      options={options}
                      values={options.filter((opt) => opt.value === formData.reject_booking_gps_off)}
                      name="reject_booking_gps_off"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "reject_booking_gps_off")}
                      styles={customSelectStyles}
                    />
                    {errorsGeneralSetting?.data?.reject_booking_gps_off && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.reject_booking_gps_off}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Reject Booking Internet Off")}</Form.Label>
                    <Select
                      options={options}
                      values={options.filter((opt) => opt.value === formData.reject_booking_internet_off)}
                      name="reject_booking_internet_off"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "reject_booking_internet_off")}
                      styles={customSelectStyles}
                    />
                    {errorsGeneralSetting?.data?.reject_booking_internet_off && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.reject_booking_internet_off}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Booking Approval")}</Form.Label>
                    <Select
                      options={options}
                      values={options.filter((opt) => opt.value === formData.booking_approval)}
                      name="booking_approval"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "booking_approval")}
                      styles={customSelectStyles}
                    />
                    {errorsGeneralSetting?.data?.booking_approval && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.booking_approval}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("A.E.N / J.E.N Approval")}</Form.Label>
                    <Select
                      options={options}
                      values={options.filter((opt) => opt.value === formData.aen_jen_approval)}
                      name="aen_jen_approval"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "aen_jen_approval")}
                      styles={customSelectStyles}
                    />
                    {errorsGeneralSetting?.data?.aen_jen_approval && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.aen_jen_approval}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Send Remark Mail")}</Form.Label>
                    <Select
                      options={options}
                      values={options.filter((opt) => opt.value === formData.send_remark_mail)}
                      name="send_remark_mail"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "send_remark_mail")}
                      styles={customSelectStyles}
                    />
                    {errorsGeneralSetting?.data?.send_remark_mail && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.send_remark_mail}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Get Otp via Misscall")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.get_otp_via_misscall}
                    name="get_otp_via_misscall"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Aen/Jen Booking Approval By")}</Form.Label>
                    <Select
                      options={options2}
                      values={options2.filter((opt) => opt.value === formData.aen_jen_otp_via)}
                      name="aen_jen_otp_via"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "aen_jen_otp_via")}
                      styles={customSelectStyles}
                    />
                    {errorsGeneralSetting?.data?.aen_jen_otp_via && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.aen_jen_otp_via}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Provisional Approval OTP By")}</Form.Label>
                    <Select
                      options={options2}
                      values={options2.filter((opt) => opt.value === formData.aen_jen_provisional_via)}
                      name="aen_jen_provisional_via"
                      labelField={"label"}
                      valueField={"value"}
                      onChange={(selectedData) => selectHandler(selectedData, "aen_jen_provisional_via")}
                      styles={customSelectStyles}
                    />
                    {errorsGeneralSetting?.data?.aen_jen_provisional_via && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsGeneralSetting?.data?.aen_jen_provisional_via}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={3}>
                  <FormInput
                    label={t("Journey Distance Calculation")}
                    type="text"
                    errors={errorsGeneralSetting?.data}
                    value={formData.journay_distance_calculation}
                    name="journay_distance_calculation"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>
              </Row>
              
              <div className="d-flex justify-content-center">
                {loading ? 
                  <Button color="primary" disabled>
                    <Spinner
                      className="spinner-border-sm me-1"
                      tag="span"
                      color="white"
                    />
                    Please wait ...
                  </Button>
                :
                  <Button type="button" onClick={handleSubmit} disabled={loading}>
                    Submit
                  </Button>
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingGeneralSetting && (<Preloader/>)}
    </>
  );
};

export default Dashboard;