import axios from "axios";

export const getVendorList =
  (token, limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_VENDORLIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Vendor`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_VENDORLIST_FULFILLED",
        payload: response.data?.data,
        vendorCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_VENDORLIST_REJECTED",
        payload: error.response,
      });
    }
  };


  export const saveVendorData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_VENDOR_PENDING" });
  
      const response = await axios.post(
        `/api/admin/v1/Add-Vendor`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_VENDOR_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_VENDOR_REJECTED", payload: error.response });
    }
  };
  

  export const getVendorListById =(token,id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_VENDORLISTBYID_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Vendor/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_VENDORLISTBYID_FULFILLED",
        payload: response.data?.data,
        cityCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_VENDORLISTBYID_REJECTED",
        payload: error.response,
      });
    }
  };


export const updateVendorData = (token, data, id) => async (dispatch) => {
  try {
    console.log('token, data', token, data);
    dispatch({ type: "UPDATE_VENDOR_PENDING" });

    const response = await axios.post(
      `/api/admin/v1/Update-Vendor/${id}`, // Endpoint URL with City ID
      data, // Data payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_VENDOR_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_VENDOR_REJECTED", payload: error.response });
  }
};


export const updateVendorStatusData = (token, id, status) => async (dispatch) => {
  try {
    //console.log('token, data', token, data);
    dispatch({ type: "UPDATE_VENDOR_STATUS_PENDING" });
    const data={
      "status":status
    }
    const response = await axios.post(
      `/api/admin/v1/Update-Vendor-Status/${id}`, // Endpoint URL with vehicle ID
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_VENDOR_STATUS_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_VENDOR_STATUS_REJECTED", payload: error.response });
  }
};


export const deleteVendorData = (token, id) => async (dispatch) => {
  try {
    console.log('token, id', token, id);
    dispatch({ type: "DELETE_VENDOR_PENDING" });

    const response = await axios.delete(
      `/api/admin/v1/Delete-Vendor/${id}`, // Endpoint URL with City ID
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "DELETE_VENDOR_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "DELETE_VENDOR_REJECTED", payload: error.response });
  }
};