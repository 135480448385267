import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { saveDriverData, getDriverListById, updateDriverData } from "../../redux/actions/DriverActions";
import { getAllStateList, getAllCityStateWiseList, getMasterVehicleList, getMasterVendorList, getAllUsertypeUserList } from "../../redux/actions/MasterActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import HyperDatepicker from "../../Components/Datepicker";
import {getDateTimeMysqlGivenFormatYmd } from '../../util/generalUtills';
import Preloader from "../../Components/Preloader";

const Dashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [stateId, setStateId] = useState(null);
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    driverReducer: {
      driverLists,
      loadingDriver,
      errorsDriver,
      changedDriverStatus,
    },
    masterReducer: {
      masterStateLists,
      masterCityStateWiseLists,
      masterVehicleLists,
      masterVendorLists,
      masterUsertypeUserLists,
      loadingMaster,
      errorsMaster,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (auth?.token) {
      dispatch(getAllStateList(auth.token));
      dispatch(getMasterVehicleList(auth.token));
      dispatch(getMasterVendorList(auth.token));
      dispatch(getAllUsertypeUserList(auth.token, 13,''));
    }
  }, [auth?.token]);

  useEffect(() => {
    if (auth?.token && stateId) {
      dispatch(getAllCityStateWiseList(auth.token, stateId));
    }
  }, [auth?.token, stateId, dispatch]);

  useEffect(() => {
    dispatch(getDriverListById(auth?.token, id));
  }, [auth, id]);

  
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(id){
      dispatch(updateDriverData(auth?.token, formData,id));
    }
    else{
      dispatch(saveDriverData(auth?.token, formData));
    }
  };

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(() => {
    if (errorsDriver && Object.keys(errorsDriver).length > 0)
    {
      if (errorsDriver?.status === 409)
      {
        swal.fire({
          show: true,
          title: "Oops!",
          text: errorsDriver?.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
        dispatch({ type: 'RESET_DELETE_DRIVER_STATUS' });
      }
      else if (errorsDriver?.status === 400)
      {
        // Handle other status
      }
      else
      {
        dispatch({ type: 'RESET_DELETE_DRIVER_STATUS' });
      }
    }
  }, [errorsDriver, dispatch]);

  useEffect(() => {
    if(id)
      {
        if(driverLists && Object.keys(driverLists).length>0)
        {
        setFormData({
          driver_id: driverLists?.driver_id,
          driver_name: driverLists?.driver_name,
          driver_mobile: driverLists?.driver_mobile,
          driver_address: driverLists?.driver_address,
          driver_dl_no: driverLists?.driver_dl_no,
          password: driverLists?.password,
          aadhar_no: driverLists?.aadhar_no,
          dl_expiry: driverLists?.dl_expiry,
          vehicle_id: driverLists?.vehicle_id ,
          vendor_id: driverLists?.vendor_id ,
          jen_id: driverLists?.jen_id || auth?.id,
          driver_city: driverLists?.driver_city,
          state_id: driverLists?.driverCityDriverCities?.cities_state?.id,
        });
      }
    }
  }, [driverLists]);

  useEffect(() => {
    if (changedDriverStatus?.success) {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedDriverStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_DRIVER_STATUS' });
      navigate(-1);
    }
  }, [changedDriverStatus, dispatch, navigate]);

  useEffect(() => {
    setFormData({});
    dispatch({ type: 'RESET_DELETE_DRIVER_STATUS' });
  }, [dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectHandler = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData[0].value,
    }));
  };

  const selectHandler2 = (selectedData, name) => {
    const selectedId = selectedData[0].id;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedId
    }));

    if (name === "state_id") {
      setStateId(selectedId); // Set the state ID to trigger fetching city list
    }
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      }
    }),
  };

  const onDateChange = (name,value) => {
    if (value) {
      setFormData({ ...formData, [name]: getDateTimeMysqlGivenFormatYmd(value) });
    }
  };

  const transformedOptions = useMemo(() => 
    masterVehicleLists.map(vehicle => ({
      ...vehicle,
      concatenatedLabel: `${vehicle.vehicle_number} - ${vehicle.vehicle_model}` // Replace 'anotherField' with the actual field you want to concatenate
    })), 
    [masterVehicleLists]
  );

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Driver"), path: "Driver" },
          { label: t("Add Driver"), path: "/Add-Driver", active: true },
        ]}
        title={t("Add Driver")}
      />
      <Row>
        <Col lg={1}></Col>
        <Col lg={10}>
          <Card>
            <Card.Body>
              {errorsDriver?.data?.message && (
                <div className="alert alert-danger">
                  {errorsDriver?.data?.message}
                </div>
              )}

              <Row>
                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("J.E.N.")}</Form.Label>
                    <Select
                      options={masterUsertypeUserLists}
                      values={[]}
                      name="jen_id"
                      labelField={"name"}
                      valueField={"id"}
                      onChange={(selectedData) => selectHandler2(selectedData,"jen_id")}
                      styles={customSelectStyles}
                    />
                    {errorsDriver?.data?.jen_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsDriver?.data?.jen_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Vendor")}</Form.Label>
                    <Select
                      options={masterVendorLists}
                      values={masterVendorLists?.filter(name => name.id === formData.vendor_id)}
                      name="vendor_id"
                      labelField={"name"}
                      valueField={"id"}
                      onChange={(selectedData) => selectHandler2(selectedData, "vendor_id")}
                      styles={customSelectStyles}
                    />
                    
                    {errorsDriver?.data?.vendor_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsDriver?.data?.vendor_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Vehicle")}</Form.Label>
                    <Select
                      options={transformedOptions}
                      values={transformedOptions?.filter(option => option.id === formData.vehicle_id)}
                      name="vehicle_id"
                      labelField={"concatenatedLabel"}
                      valueField={"id"}
                      onChange={(selectedData) => selectHandler2(selectedData, "vehicle_id")}
                      styles={customSelectStyles}
                    />
                    
                    {errorsDriver?.data?.vehicle_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsDriver?.data?.vehicle_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>
                {(!id) && (
                  <>
                    <Col lg={4}>
                      <FormInput
                        label={t("Driver ID")}
                        type="text"
                        errors={errorsDriver?.data}
                        value={formData.driver_id}
                        name="driver_id"
                        onChange={inputHandlerCaps}
                        containerClass={"mb-3"}
                      />
                    </Col>
                  </>
                )}
                

                <Col lg={4}>
                  <FormInput
                    label={t("Driver Name")}
                    type="text"
                    errors={errorsDriver?.data}
                    value={formData.driver_name}
                    name="driver_name"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <FormInput
                    label={t("Mobile Number")}
                    type="text"
                    errors={errorsDriver?.data}
                    value={formData.driver_mobile}
                    name="driver_mobile"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("State")}</Form.Label>
                    <Select
                      options={masterStateLists}
                      values={masterStateLists?.filter(state => state.id === formData.state_id)}
                      name="state_id"
                      labelField={"state"}
                      valueField={"id"}
                      onChange={(selectedData) => selectHandler2(selectedData, "state_id")}
                      styles={customSelectStyles}
                    />
                    
                    {errorsDriver?.data?.state_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsDriver?.data?.state_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("City")}</Form.Label>
                    <Select
                      options={masterCityStateWiseLists}
                      values={masterCityStateWiseLists?.filter(city => city.id === formData.driver_city)}
                      name="driver_city"
                      labelField={"city_name"}
                      valueField={"id"}
                      onChange={(selectedData) => selectHandler2(selectedData, "driver_city")}
                      styles={customSelectStyles}
                    />
                    
                    {errorsDriver?.data?.driver_city && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsDriver?.data?.driver_city}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <FormInput
                    label={t("Address")}
                    type="text"
                    errors={errorsDriver?.data}
                    value={formData.driver_address}
                    name="driver_address"
                    onChange={inputHandler}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <FormInput
                    label={t("DL Number")}
                    type="text"
                    errors={errorsDriver?.data}
                    value={formData.driver_dl_no}
                    name="driver_dl_no"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <FormInput
                    label={t("Aadhaar Number")}
                    type="text"
                    errors={errorsDriver?.data}
                    value={formData.aadhar_no}
                    name="aadhar_no"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <FormInput
                    label={t("Password")}
                    type="text"
                    errors={errorsDriver?.data}
                    value={formData.password}
                    name="password"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("DL Expiry")}</Form.Label><br />
                    <HyperDatepicker
                      hideAddon={true}
                      minDate={new Date()}
                      value={formData.dl_expiry}
                      onChange={(date) => {
                        onDateChange("dl_expiry",date);
                      }}
                    />
                    {errorsDriver?.data?.dl_expiry && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsDriver?.data.dl_expiry}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              
              <div className="d-flex justify-content-center">
                {loading ? 
                  <Button color="primary" disabled>
                    <Spinner
                      className="spinner-border-sm me-1"
                      tag="span"
                      color="white"
                    />
                    Please wait ...
                  </Button>
                :
                  <Button type="button" onClick={handleSubmit} disabled={loading}>
                    Submit
                  </Button>
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingDriver && (<Preloader/>)}
      {loadingMaster && (<Preloader/>)}

    </>
  );
};

export default Dashboard;