import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { saveUsertypeData } from "../../redux/actions/UsertypeActions";
import { getAllStateList } from "../../redux/actions/MasterActions";
import { getAllCityList } from "../../redux/actions/MasterActions";
import FormInput from "../../Components/FormInput";
import { useNavigate } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';

const Dashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const [stateId, setStateId] = useState(null);
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    usertypeReducer: {
      loadingUsertype,
      errorsUsertype,
      changedUsertypeStatus,
    },
    masterReducer: {
      masterStateLists,
      masterCityStateWiseLists,
      loadingMaster,
      errorsMaster,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (auth?.token) {
      dispatch(getAllStateList(auth.token));
    }
  }, [auth?.token]);

  
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    dispatch(saveUsertypeData(auth?.token, formData));
  };

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(() => {
    if (errorsUsertype && Object.keys(errorsUsertype).length > 0)
    {
      if (errorsUsertype?.status === 409)
      {
        swal.fire({
          show: true,
          title: "Oops!",
          text: errorsUsertype?.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
        dispatch({ type: 'RESET_DELETE_USERTYPE_STATUS' });
      }
      else if (errorsUsertype?.status === 400)
      {
        // Handle other status
      }
      else
      {
        dispatch({ type: 'RESET_DELETE_USERTYPE_STATUS' });
      }
    }
  }, [errorsUsertype, dispatch]);

  useEffect(() => {
    if (changedUsertypeStatus?.success) {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedUsertypeStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_USERTYPE_STATUS' });
      navigate(-1);
    }
  }, [changedUsertypeStatus, dispatch, navigate]);

  useEffect(() => {
    setFormData({});
    dispatch({ type: 'RESET_DELETE_USERTYPE_STATUS' });
  }, [dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectHandler = (selectedData, name) => {
    const selectedId = selectedData[0].id;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedId
    }));

    if (name === "state_id") {
      setStateId(selectedId); // Set the state ID to trigger fetching city list
    }
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      }
    }),
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Usertype"), path: "Usertype" },
          { label: t("Add Usertype"), path: "/Add-Usertype", active: true },
        ]}
        title={t("Add Usertype")}
      />
      <Row>
        <Col lg={4}></Col>
        <Col lg={4}>
          <Card>
            <Card.Body>
              {errorsUsertype?.data?.message && (
                <div className="alert alert-danger">
                  {errorsUsertype?.data?.message}
                </div>
              )}
              

              <FormInput
                label={t("Name")}
                type="text"
                errors={errorsUsertype?.data}
                value={formData.name}
                name="name"
                onChange={inputHandler}
                containerClass={"mb-3"}
              />
              
              <div className="d-flex justify-content-center">
                {loading ? 
                  <Button color="primary" disabled>
                    <Spinner
                      className="spinner-border-sm me-1"
                      tag="span"
                      color="white"
                    />
                    Please wait ...
                  </Button>
                :
                  <Button type="button" onClick={handleSubmit} disabled={loading}>
                    Submit
                  </Button>
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;