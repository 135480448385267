import axios from "axios"
export const login = (data) => async(dispatch) => {
    try {
        dispatch({type:"LOGIN_PENDING"})
        const response = await axios.post("/api/admin/V1/admin/login",data)
        localStorage.setItem("asg_phedr_admin",JSON.stringify(response.data))
        dispatch({type:"LOGIN_FULFILLED",payload:response.data})
        
    } catch (error) {
        dispatch({type:"LOGIN_FAILED",payload:error.response})
    }
} 

export const logout = () => async(dispatch) =>{
   localStorage.removeItem("asg_phedr_admin")
   dispatch({type:"LOG_OUT_FULFILLED"})
}

