const initialState = {
    tripReportLists: [],
    revenueReportLists: [],
    monthlyRevenueReportLists: [],
    loadingReport: false,
    errorsReport: {}
}

const  verifiedReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_TRIP_REPORT_PENDING":
        case "GET_REVENUE_REPORT_PENDING":
        case "GET_MONTHLY_REVENUE_REPORT_PENDING":
            return { ...state, loadingReport: true };

        case "GET_TRIP_REPORT_FULFILLED":
            return {
                ...state,
                loadingReport: false,
                tripReportLists: action.payload,
                reportCount: action.reportCount
            };

        case "GET_REVENUE_REPORT_FULFILLED":
            return {
                ...state,
                loadingReport: false,
                revenueReportLists: action.payload,
                reportCount: action.reportCount
            };

        case "GET_MONTHLY_REVENUE_REPORT_FULFILLED":
            return {
                ...state,
                loadingReport: false,
                monthlyRevenueReportLists: action.payload,
                reportCount: action.reportCount
            };

        case "RESET_VERIFIED_REPORT_STATUS":
            return {
                ...state,
                loadingReport: false,
                errorsReport:{}
            };

        case "GET_TRIP_REPORT_REJECTED":
        case "GET_REVENUE_REPORT_REJECTED":
        case "GET_MONTHLY_REVENUE_REPORT_REJECTED":
            return {
                ...state,
                loadingReport: false,
                errorsReport: action.payload
            };

        default:
            return state;
    }
}

export default verifiedReportReducer;