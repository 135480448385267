const initialState = {
    provisionalTripReportLists: [],
    provisionalRevenueReportLists: [],
    provisionalMonthlyRevenueReportLists: [],
    loadingReport: false,
    errorsReport: {}
}

const  provisionalReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_PROVISIONAL_TRIP_REPORT_PENDING":
        case "GET_PROVISIONAL_REVENUE_REPORT_PENDING":
        case "GET_PROVISIONAL_MONTHLY_REVENUE_REPORT_PENDING":
            return { ...state, loadingReport: true };

        case "GET_PROVISIONAL_TRIP_REPORT_FULFILLED":
            return {
                ...state,
                loadingReport: false,
                provisionalTripReportLists: action.payload,
                reportCount: action.reportCount
            };

        case "GET_PROVISIONAL_REVENUE_REPORT_FULFILLED":
            return {
                ...state,
                loadingReport: false,
                provisionalRevenueReportLists: action.payload,
                reportCount: action.reportCount
            };

        case "GET_PROVISIONAL_MONTHLY_REVENUE_REPORT_FULFILLED":
            return {
                ...state,
                loadingReport: false,
                provisionalMonthlyRevenueReportLists: action.payload,
                reportCount: action.reportCount
            };

        case "RESET_VERIFIED_REPORT_STATUS":
            return {
                ...state,
                loadingReport: false,
                errorsReport:{}
            };

        case "GET_PROVISIONAL_TRIP_REPORT_REJECTED":
        case "GET_PROVISIONAL_REVENUE_REPORT_REJECTED":
        case "GET_PROVISIONAL_MONTHLY_REVENUE_REPORT_REJECTED":
            return {
                ...state,
                loadingReport: false,
                errorsReport: action.payload
            };

        default:
            return state;
    }
}

export default provisionalReportReducer;