import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { getVehicleListById, updateVehicleData, saveVehicleData } from "../../redux/actions/VehicleActions";
import { getAllUsertypeUserList, getMasterVendorList } from "../../redux/actions/MasterActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import HyperDatepicker from "../../Components/Datepicker";
import {getDateTimeMysqlGivenFormatYmd } from '../../util/generalUtills'
import Preloader from "../../Components/Preloader";

const Dashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    rc_expiry:new Date(),
    permit_expiry:new Date(),
    insurance_expiry:new Date(),
    fitness_expiry:new Date(),
  });
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    vehicleReducer: {
      loadingVehicle,
      errorsVehicle,
      vehicleLists,
      changedVehicleStatus,
    },
    masterReducer: {
      masterVendorLists,
      masterUsertypeUserLists,
      loadingMaster,
      errorsMaster,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (auth?.token) {
      dispatch(getMasterVendorList(auth.token));
      dispatch(getAllUsertypeUserList(auth.token, 13,''));
    }
  }, [auth?.token]);

  useEffect(() => {
    if(id)
    {
      dispatch(getVehicleListById(auth?.token, id));
    }
  }, [auth, id]);

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(id)
    {
      dispatch(updateVehicleData(auth?.token, formData,id));
    }
    else
    {
      dispatch(saveVehicleData(auth?.token, formData));
    }
  };

  useEffect(()=>{
    if(id)
    {
      if(vehicleLists && Object.keys(vehicleLists).length>0)
      {
        setFormData({
          vehicle_number: vehicleLists?.vehicle_number,
          vehicle_model: vehicleLists?.vehicle_model,
          rc_expiry: vehicleLists?.rc_expiry,
          permit_expiry: vehicleLists?.permit_expiry,
          insurance_expiry: vehicleLists?.insurance_expiry,
          fitness_expiry: vehicleLists?.fitness_expiry,
          rf_id: vehicleLists?.rf_id,
          vendor_id: vehicleLists?.vendorIdVehicleVendor?.id ,
          user_id: vehicleLists?.user_id || auth?.id,
        });
      }
    }
    
  },[vehicleLists])

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(() => {
    if (errorsVehicle && Object.keys(errorsVehicle).length > 0) {
      if (errorsVehicle?.status === 409) {
        swal.fire({
          show: true,
          title: "Oops!",
          text: errorsVehicle?.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
        dispatch({ type: 'RESET_DELETE_VEHICLE_STATUS' });
      } else if (errorsVehicle?.status === 400) {
        // Handle other status
      } else {
        dispatch({ type: 'RESET_DELETE_VEHICLE_STATUS' });
      }
    }
  }, [errorsVehicle, dispatch]);

  useEffect(() => {
    if (changedVehicleStatus?.success) {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedVehicleStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_VEHICLE_STATUS' });
      navigate(-1);
    }
  }, [changedVehicleStatus, dispatch, navigate]);

  // Reset formData and errorsVehicle when the component mounts
  useEffect(() => {
    setFormData({});
    dispatch({ type: 'RESET_DELETE_VEHICLE_STATUS' });
  }, [dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectHandler = (selectedData, name) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData[0].value,
    }));
  };

  const selectHandler2 = (selectedData, name) => {
    const selectedId = selectedData[0].id;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedId
    }));
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errorsVehicle?.data?.vehicle_model ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errorsVehicle?.data?.vehicle_model ? 'red' : base.borderColor,
      }
    }),
  };

  const onDateChange = (name,value) => {
    if (value) {
      setFormData({ ...formData, [name]: getDateTimeMysqlGivenFormatYmd(value) });
    }
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Vehicle"), path: "Vehicle" },
          { label: t("Add Vehicle"), path: "/Add-Vehicle", active: true },
        ]}
        title={t("Add Vehicle")}
      />
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              {errors?.data?.message && (
                <div className="alert alert-danger">
                  {errors.data.message}
                </div>
              )}
              <Row>
                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Vendor")}</Form.Label>
                    <Select
                      options={masterVendorLists}
                      values={masterVendorLists?.filter(name => name.id === formData.vendor_id)}
                      name="vendor_id"
                      labelField={"name"}
                      valueField={"id"}
                      onChange={(selectedData) => selectHandler2(selectedData, "vendor_id")}
                      styles={customSelectStyles}
                    />
                    
                    {errorsVehicle?.data?.vendor_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsVehicle?.data?.vendor_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("J.E.N.")}</Form.Label>
                    <Select
                      options={masterUsertypeUserLists}
                      values={[]}
                      name="user_id"
                      labelField={"name"}
                      valueField={"id"}
                      onChange={(selectedData) => selectHandler2(selectedData, "user_id")}
                      styles={customSelectStyles}
                    />
                    {errorsVehicle?.data?.user_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsVehicle?.data?.user_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <FormInput
                    label={t("Vehicle Number")}
                    type="text"
                    errors={errorsVehicle?.data}
                    value={formData.vehicle_number}
                    name="vehicle_number"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>

                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Vehicle Type")}</Form.Label>
                    <Select
                      classNamePrefix="react-select"
                      name="vehicle_model"
                      defaultValue={formData.vehicle_model}
                      options={[
                        { value: "Tractor", label: "Tractor" },
                      ]}
                      onChange={(selectedData) => selectHandler(selectedData, "vehicle_model")}
                      styles={customSelectStyles}
                    />
                    {errorsVehicle?.data?.vehicle_model && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsVehicle?.data.vehicle_model}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("RC Expiry")}</Form.Label><br />
                    <HyperDatepicker
                      hideAddon={true}
                      minDate={new Date()}
                      value={formData.rc_expiry}
                      onChange={(date) => {
                        onDateChange("rc_expiry",date);
                      }}
                    />
                    {errorsVehicle?.data?.rc_expiry && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsVehicle?.data.rc_expiry}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Permit Expiry")}</Form.Label>
                    <HyperDatepicker
                      hideAddon={true}
                      minDate={new Date()}
                      value={formData.permit_expiry}
                      onChange={(date) => {
                        onDateChange("permit_expiry",date);
                      }}
                    />
                    {errorsVehicle?.data?.permit_expiry && (
                      <div className="invalid-feedback d-block">
                      <p className="text-danger">{errorsVehicle?.data.permit_expiry}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Insurance Expiry")}</Form.Label>
                    <HyperDatepicker
                      hideAddon={true}
                      minDate={new Date()}
                      value={formData.insurance_expiry}
                      onChange={(date) => {
                        onDateChange("insurance_expiry",date);
                      }}
                    />
                    {errorsVehicle?.data?.insurance_expiry && (
                      <div className="invalid-feedback d-block">
                      <p className="text-danger">{errorsVehicle?.data.insurance_expiry}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Fitness Expiry")}</Form.Label>
                    <HyperDatepicker
                      hideAddon={true}
                      minDate={new Date()}
                      value={formData.fitness_expiry}
                      onChange={(date) => {
                        onDateChange("fitness_expiry",date);
                      }}
                    />
                    {errorsVehicle?.data?.fitness_expiry && (
                      <div className="invalid-feedback d-block">
                      <p className="text-danger">{errorsVehicle?.data.fitness_expiry}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={4}>
                  <FormInput
                    label={t("Remark")}
                    type="text"
                    errors={errorsVehicle?.data}
                    value={formData.rf_id}
                    name="rf_id"
                    onChange={inputHandlerCaps}
                    containerClass={"mb-3"}
                  />
                </Col>
              </Row>

              <div className="d-flex justify-content-center">
                {loading ? 
                  <Button color="primary" disabled>
                    <Spinner
                      className="spinner-border-sm me-1"
                      tag="span"
                      color="white"
                    />
                    Please wait ...
                  </Button>
                :
                  <Button type="button" onClick={handleSubmit} disabled={loading}>
                    Submit
                  </Button>
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingMaster && (<Preloader/>)}
      {loadingVehicle && (<Preloader/>)}
    </>
  );
};

export default Dashboard;