import axios from "axios";

export const getStateList =
  (token, limit = 10, page = 1, search = "", sort = "desc") =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_STATELIST_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/State`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_STATELIST_FULFILLED",
        payload: response.data?.data,
        stateCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_STATELIST_REJECTED",
        payload: error.response,
      });
    }
  };


  export const saveStateData = (token, data) => async (dispatch) => {
    try {
      console.log('token, data', token, data);
      dispatch({ type: "ADD_STATE_PENDING" });
  
      const response = await axios.post(
        `/api/admin/v1/Add-State`,
        data, // Data payload should be the second argument
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: "ADD_STATE_FULFILLED", payload: response.data });
    } catch (error) {
      //console.log(error.response.data);
      dispatch({ type: "ADD_STATE_REJECTED", payload: error.response });
    }
  };
  

  export const getStateListById =(token,id) =>
  async (dispatch) => {
    try {
      dispatch({ type: "GET_STATELISTBYID_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/State/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_STATELISTBYID_FULFILLED",
        payload: response.data?.data,
        stateCount: response.data?.count,
      });
    } catch (error) {
      console.log(error);
      dispatch({
        type: "GET_STATELISTBYID_REJECTED",
        payload: error.response,
      });
    }
  };


export const updateStateData = (token, data, id) => async (dispatch) => {
  try {
    console.log('token, data', token, data);
    dispatch({ type: "UPDATE_STATE_PENDING" });

    const response = await axios.post(
      `/api/admin/v1/Update-State/${id}`, // Endpoint URL with vehicle ID
      data, // Data payload
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_STATE_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_STATE_REJECTED", payload: error.response });
  }
};


export const updateStateStatusData = (token, id, status) => async (dispatch) => {
  try {
    //console.log('token, data', token, data);
    dispatch({ type: "UPDATE_STATE_STATUS_PENDING" });
    const data={
      "status":status
    }
    const response = await axios.post(
      `/api/admin/v1/Update-State-Status/${id}`, // Endpoint URL with vehicle ID
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "UPDATE_STATE_STATUS_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "UPDATE_STATE_STATUS_REJECTED", payload: error.response });
  }
};


export const deleteStateData = (token, id) => async (dispatch) => {
  try {
    console.log('token, id', token, id);
    dispatch({ type: "DELETE_STATE_PENDING" });

    const response = await axios.delete(
      `/api/admin/v1/Delete-State/${id}`, // Endpoint URL with vehicle ID
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    dispatch({ type: "DELETE_STATE_FULFILLED", payload: response.data });
  } catch (error) {
    dispatch({ type: "DELETE_STATE_REJECTED", payload: error.response });
  }
};