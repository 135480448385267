const initialState = {
    hydrantLists: [],
    hydrantStatus: [],
    changedHydrantStatus : [],
    loadingHydrant: false,
    errorsHydrant: {}
}

const  hydrantReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_HYDRANTLIST_PENDING":
        case "ADD_HYDRANT_PENDING":
        case "UPDATE_HYDRANT_PENDING":
        case "UPDATE_HYDRANT_STATUS_PENDING":
        case "DELETE_HYDRANT_PENDING":
        case "GET_HYDRANTLISTBYID_PENDING":
            return { ...state, loadingHydrant: true };

        case "GET_HYDRANTLIST_FULFILLED":
            return {
                ...state,
                loadingHydrant: false,
                hydrantLists: action.payload,
                hydrantCount: action.hydrantCount
            };

        case "ADD_HYDRANT_FULFILLED":
            return {
                ...state,
                loadingHydrant: false,
                changedHydrantStatus: action.payload,
            };

        case "UPDATE_HYDRANT_FULFILLED":
            return {
                ...state,
                loadingHydrant: false,
                changedHydrantStatus: action.payload,
            };

        case "UPDATE_HYDRANT_STATUS_FULFILLED":
            return {
                ...state,
                loadingHydrant: false,
                changedHydrantStatus: action.payload,
            };

        case "GET_HYDRANTLISTBYID_FULFILLED":
            return {
                ...state,
                loadingHydrant: false,
                hydrantLists: action.payload
            };

        case "DELETE_HYDRANT_FULFILLED":
            return {
                ...state,
                loadingHydrant: false,
                changedHydrantStatus: action.payload
            };

        case "RESET_DELETE_HYDRANTS_STATUS":
            return {
                ...state,
                loadingHydrant: false,
                changedHydrantStatus: [],
                errorsHydrant:{}
            };

        case "GET_HYDRANTLIST_REJECTED":
        case "ADD_HYDRANT_REJECTED":
        case "UPDATE_HYDRANT_REJECTED":
        case "UPDATE_HYDRANT_STATUS_REJECTED":
        case "DELETE_HYDRANT_REJECTED":
        case "GET_HYDRANTLISTBYID_REJECTED":
            return {
                ...state,
                loadingHydrant: false,
                errorsHydrant: action.payload
            };

        default:
            return state;
    }
}

export default hydrantReducer;