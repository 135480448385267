const initialState = {
    liveTrackingData: [],
    historyTrackingData: [],
    vehicleCount:{},
    loadingMap: false,
    errorsMap: {}
}

const  mapReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_LIVE_TRACKING_PENDING":
        case "GET_HISTORY_TRACKING_PENDING":
            return { ...state, loadingMap: true };

        case "GET_LIVE_TRACKING_FULFILLED":
            return {
                ...state,
                loadingMap: false,
                liveTrackingData: action.payload,
                vehicleCount: action.vehicleCount
            };

        case "GET_HISTORY_TRACKING_FULFILLED":
            return {
                ...state,
                loadingMap: false,
                historyTrackingData: action.payload,
            };

        case "RESET_DELETE_MAP_STATUS":
            return {
                ...state,
                loadingMap: false,
                changedHydrantStatus: [],
                errorsMap:{}
            };

        case "GET_LIVE_TRACKING_REJECTED":
        case "GET_HISTORY_TRACKING_REJECTED":
            return {
                ...state,
                loadingMap: false,
                errorsMap: action.payload
            };

        default:
            return state;
    }
}

export default mapReducer;