import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardCount } from "../redux/actions/DashboardActions";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button, Badge } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { deleteDriverData, getDriverList, updateDriverStatusData } from "../../redux/actions/DriverActions";
import RoundedPagination from "../../Components/Paginate";
import FormInput from "../../Components/FormInput";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import { getDisplayDateTimeFormat } from "../../util/generalUtills";
import Preloader from "../../Components/Preloader";



const Dashboard = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const {
    AuthReducer: { auth,errors,loading },
    driverReducer: {
      driverLists,
      loadingDriver,
      errorsDriver,
      driverCount,
      changedDriverStatus,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  
  // useEffect(() => {
  //   dispatch(getDriverList(auth?.token, paginate, page, search, sort));
  //   console.log('driverLists',driverLists);
  // }, [auth, paginate, page, search, sort]);

  const fetchDriverList = useCallback(() => {
    if (auth?.token) {
      dispatch(getDriverList(auth.token, paginate, page, search, sort));
    }
  }, [auth, paginate, page, search, sort, dispatch]);

  useDebounce(fetchDriverList, 500, [auth, paginate, page, search, sort]);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors])



  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? driverCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1); // Reset to the first page whenever the paginate value changes
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleDeleteRequest = (id) => {
    swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, delete it!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteDriverData(auth?.token,id))
      }
    });
  };

  const handleStatusChangeRequest = (id, newStatus) => {
    swal.fire({
      title: "Are you sure?",
      text: `Do you want to change the status to ${newStatus}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateDriverStatusData(auth.token, id, newStatus));
      }
    });
  };


  useEffect(() => {
    //console.log(changedDriverStatus);
    if(changedDriverStatus?.status)
    {
      swal.fire({
        show: true,
        title: "Deleted!",
        text: changedDriverStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_DRIVER_STATUS' });
      dispatch(getDriverList(auth?.token, paginate, page, search, sort));
    }
    else if(changedDriverStatus.success)
    {
      swal.fire({
        show: true,
        title: "Great !",
        text: changedDriverStatus.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_VEHICLE_STATUS' });
      dispatch(getDriverList(auth?.token, paginate, page, search, sort));
    }
  },[changedDriverStatus])



  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Driver"), path: "/Driver", active: true },
        ]}
        title={t("Driver")}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                  <Col lg={3}>
                    <div className="d-flex align-items-center">
                      <input
                        type="search"
                        placeholder="Search..."
                        className="form-control ms-1"
                        autoComplete="off"
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </Col>
                  <Col lg={9}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="button-list">
                        <Link to="/Add-Driver">
                          <Button variant="success">
                            <i className="mdi mdi-clipboard-plus-outline me-1"></i>Add
                          </Button>
                        </Link>
                        <Button variant="dark">
                          <i className="mdi mdi-printer me-1"></i>Print
                        </Button>
                        <Button variant="info">
                          <i className="mdi mdi-file-excel me-1"></i>Excel
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div>
                <Table className="mb-0 table-bordered" responsive size="sm" striped>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Vendor</th>
                      <th>Vehicle Number</th>
                      <th>Driver ID</th>
                      <th>Driver Name</th>
                      <th>Mobile Number</th>
                      <th>Driving License</th>
                      <th>State/City</th>
                      <th>Address</th>
                      <th>Status</th>
                      <th>Registered At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {driverLists.length === 0 ? (
                    <tr>
                      <td colSpan="11">No data found</td>
                    </tr>
                  ) : (<>
                    {driverLists?.map?.((item, index) => {
                      return (
                        <tr key={item.id}>
                          <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                          <td>{item?.vendorIdDriverVendor?.name}</td>
                          <td>{item?.vehicleIdDriverVehicle?.vehicle_number}</td>
                          <td>{item?.driver_id}</td>
                          <td>{item?.driver_name}</td>
                          <td>{item?.driver_mobile}</td>
                          <td>{item?.driver_dl_no + ' / EXP -: '  + getDisplayDateTimeFormat(item?.dl_expiry)}</td>
                          <td>{item?.driverCityDriverCities?.cities_state?.state + ' ' + item?.driverCityDriverCities?.city_name}</td>
                          <td>{item?.driver_address}</td>
                          <td>{item?.status === "Active" ? <Badge className={"bg-success"}>{item?.status}</Badge> : <><Badge className={"bg-danger"}>{item?.status}</Badge></>}</td>
                          <td>{getDisplayDateTimeFormat(item?.createdAt)}</td>
                          <td>
                            {item?.status === "Active" && (
                              <>
                                <Link to="#" className="action-icon text-success" onClick={() => handleStatusChangeRequest(item?.id , "In-Active")}>
                                  {" "}
                                  <i className="mdi mdi-eye"></i>
                                </Link>
                              </>
                            )}
                            {item?.status === "In-Active" && (
                              <>
                                <Link to="#" className="action-icon text-warning" onClick={() => handleStatusChangeRequest(item?.id, "Active")}>
                                  {" "}
                                  <i className="mdi mdi-eye-off"></i>
                                </Link>
                              </>
                            )}

                            <Link to={`/Update-Vehicle/${item?.id}`} className="action-icon text-info"> 
                              <i className="mdi mdi-square-edit-outline"></i>
                            </Link>

                            <Link to="#" className="action-icon text-danger" onClick={() => handleDeleteRequest(item?.id)} >
                              {" "}
                              <i className="mdi mdi-delete"></i>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}</>
                  )}
                  </tbody>
                </Table>
              </div>
              <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
                <div class="d-inline-block me-3">
                  <label class="me-1">Display :</label>
                  <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span class="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(driverCount / paginate)}</strong> </span>
                <RoundedPagination currentPage={page} totalPages={Math.ceil(driverCount / paginate)} onPageChange={(pageNumber) => setPage(pageNumber)}/>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingDriver && (<Preloader/>)}
    </>
  );
};

export default Dashboard;
