const initialDriver = {
    driverLists: [],
    driverStatus: [],
    changedDriverStatus : [],
    loadingDriver: false,
    errorsDriver: {}
}

const  driverReducer = (state = initialDriver, action) => {
    switch (action.type) {
        case "GET_DRIVERLIST_PENDING":
        case "ADD_DRIVER_PENDING":
        case "UPDATE_DRIVER_PENDING":
        case "UPDATE_DRIVER_STATUS_PENDING":
        case "DELETE_DRIVER_PENDING":
        case "GET_DRIVERLISTBYID_PENDING":
            return { ...state, loadingDriver: true };

        case "GET_DRIVERLIST_FULFILLED":
            return {
                ...state,
                loadingDriver: false,
                driverLists: action.payload,
                driverCount: action.driverCount
            };

        case "ADD_DRIVER_FULFILLED":
            return {
                ...state,
                loadingDriver: false,
                changedDriverStatus: action.payload,
            };

        case "UPDATE_DRIVER_FULFILLED":
            return {
                ...state,
                loadingDriver: false,
                changedDriverStatus: action.payload,
            };

        case "UPDATE_DRIVER_STATUS_FULFILLED":
            return {
                ...state,
                loadingDriver: false,
                changedDriverStatus: action.payload,
            };

        case "GET_DRIVERLISTBYID_FULFILLED":
            return {
                ...state,
                loadingDriver: false,
                driverLists: action.payload
            };

        case "DELETE_DRIVER_FULFILLED":
            return {
                ...state,
                loadingDriver: false,
                changedDriverStatus: action.payload
            };

        case "RESET_DELETE_DRIVER_STATUS":
            return {
                ...state,
                loadingDriver: false,
                changedDriverStatus: [],
                errorsDriver:{}
            };

        case "GET_DRIVERLIST_REJECTED":
        case "ADD_DRIVER_REJECTED":
        case "UPDATE_DRIVER_REJECTED":
        case "UPDATE_DRIVER_STATUS_REJECTED":
        case "DELETE_DRIVER_REJECTED":
        case "GET_DRIVERLISTBYID_REJECTED":
            return {
                ...state,
                loadingDriver: false,
                errorsDriver: action.payload
            };

        default:
            return state;
    }
}

export default driverReducer;