import React, { useCallback, useEffect , useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button, Form, Spinner } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { getMonthlyRevenueReportList } from "../../redux/actions/VerifiedReportActions";
import { getMasterDriverList, getMasterVehicleList, getMasterVendorList} from "../../redux/actions/MasterActions";
import RoundedPagination from "../../Components/Paginate";
import FormInput from "../../Components/FormInput";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import {getDisplayDateTimeFormat} from '../../util/generalUtills'
import { CSVLink } from 'react-csv';
import DatePicker from "react-datepicker";
import Select from "react-dropdown-select";
import Preloader from "../../Components/Preloader";


const Dashboard = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const {
    AuthReducer: { auth,errors,loading },
    verifiedReportReducer: {
      monthlyRevenueReportLists,
      loadingReport,
      errorsReport,
      reportCount,
    },
    masterReducer: {
      masterVehicleLists,
      masterDriverLists,
      masterVendorLists,
      loadingMaster,
      errorsMaster,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  const [vendorId, setVendorId] = useState("");
  const [vehicleId, setVehicleId] = useState("");
  const [driverId, setDriverId] = useState("");
  const [validated, setValidated] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    if (auth?.token) {
      dispatch(getMasterVendorList(auth.token));
    }
  }, [auth?.token]);

  useEffect(()=>{
    dispatch(getMasterVehicleList(auth.token,vendorId));
    dispatch(getMasterDriverList(auth.token,vendorId));
  },[vendorId])

  const fetchMonthlyRevenueReportList = useCallback(() => {
    if (auth?.token)
    {
      dispatch(getMonthlyRevenueReportList(auth.token, paginate, page, search, sort,vendorId,vehicleId,driverId,selectedDate));
    }
  }, [auth?.token, paginate, page, search, sort, dispatch]);

  useDebounce(fetchMonthlyRevenueReportList, 500, [auth.token, paginate, page, search, sort,vendorId,vehicleId,driverId,selectedDate]);

  const csvHead = [['SR No.', 'User', 'Tanker Number', 'Total Trip', 'Distance']]
  const csvData = monthlyRevenueReportLists.map((cv, index) => [
    index + 1,
    cv?.jen_idBookingDetailsAdminUsers?.name,
    cv?.vehicle_number,
    cv?.count,
    cv?.distance,
  ])
  const exportData = csvHead.concat(csvData);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status === 401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
    
  },[errors])

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    dispatch(getMonthlyRevenueReportList(auth.token, paginate, page, search, sort,vendorId,vehicleId,driverId,selectedDate));
  };

  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? reportCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1);
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const transformedVehicleOptions = useMemo(() => 
    masterVehicleLists.map(vehicle => ({
      ...vehicle,
      concatenatedLabel: `${vehicle.vehicle_number} - ${vehicle.vehicle_model}`
    })),
    [masterVehicleLists]
  );

  const transformedDriverOptions = useMemo(() => 
    masterDriverLists.map(vehicle => ({
      ...vehicle,
      concatenatedLabel: `${vehicle.driver_id} - ${vehicle.driver_name}` 
    })),
    [masterDriverLists]
  );

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      }
    }),
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("Monthly Revenue Report"), path: "/Monthly-Revenue-Report", active: true },
        ]}
        title={t("Monthly Revenue Report")}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                <Col lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Vendor")}</Form.Label>
                    <Select
                      options={masterVendorLists}
                      values={masterVendorLists?.filter(name => name.id === vendorId)}
                      name="vendor_id"
                      labelField={"name"}
                      valueField={"id"}
                      onChange={(selectedData) => setVendorId(selectedData[0].id)}
                      styles={customSelectStyles}
                    />
                    
                    {errorsMaster?.data?.vendor_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsMaster?.data?.vendor_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Vehicle")}</Form.Label>
                    <Select
                      options={transformedVehicleOptions}
                      values={transformedVehicleOptions?.filter(option => option.id === vehicleId)}
                      name="vehicle_id"
                      labelField={"concatenatedLabel"}
                      valueField={"id"}
                      onChange={(selectedData) => setVehicleId(selectedData[0].id)}
                      styles={customSelectStyles}
                    />
                    
                    {errorsMaster?.data?.vehicle_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsMaster?.data?.vehicle_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Driver")}</Form.Label>
                    <Select
                      options={transformedDriverOptions}
                      values={transformedDriverOptions?.filter(option => option.id === vehicleId)}
                      name="driver_id"
                      labelField={"concatenatedLabel"}
                      valueField={"id"}
                      onChange={(selectedData) => setDriverId(selectedData[0].id)}
                      styles={customSelectStyles}
                    />
                    
                    {errorsMaster?.data?.vehicle_id && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsMaster?.data?.vehicle_id}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={2}>
                  <Form.Group className="mb-3">
                    <Form.Label>{t("Date")}</Form.Label>
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM"
                      maxDate={new Date()}
                      showMonthYearPicker
                      className="form-control"
                      withPortal
                    />
                    {errorsMaster?.data?.startDate && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsMaster?.data?.startDate}</p>
                      </div>
                    )}
                    {errorsMaster?.data?.endDate && (
                      <div className="invalid-feedback d-block">
                        <p className="text-danger">{errorsMaster?.data?.endDate}</p>
                      </div>
                    )}
                  </Form.Group>
                </Col>

                <Col lg={2} >
                  <div className="mb-3" style={{ marginTop: '1.6rem' }}>
                    {loadingReport ? 
                      <Button color="primary" disabled>
                        <Spinner
                          className="spinner-border-sm me-1"
                          tag="span"
                          color="white"
                        />
                        Please wait ...
                      </Button>
                    :
                      <Button type="button" onClick={handleSubmit} disabled={loadingReport}>
                        Submit
                      </Button>
                    }
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <div className="mb-2">
                <Row>
                  <Col lg={3}>
                    <div className="d-flex align-items-center">
                      <input
                        type="search"
                        placeholder="Search..."
                        className="form-control ms-1"
                        autoComplete="off"
                        value={search}
                        onChange={handleSearchChange}
                      />
                    </div>
                  </Col>
                  <Col lg={9}>
                    <div className="d-flex justify-content-end align-items-center">
                      <div className="button-list">
                        <Button variant="dark">
                          <i className="mdi mdi-printer me-1"></i>Print
                        </Button>
                        <CSVLink
                          filename="monthly-revenue-reports.csv"
                          data={exportData}
                          className="btn btn-info text-white ms-1"
                          title="Export To CSV"
                        >
                          <i className="mdi mdi-file-excel me-1"></i>
                          Excel
                        </CSVLink>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              <div>
                <Table className="mb-0 table-bordered" responsive size="sm" striped>
                  <thead>
                    <tr>
                      <th>SR No.</th>
                      <th>User</th>
                      <th>Tanker Number</th>
                      <th>Total Trip</th>
                      <th>Distance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {monthlyRevenueReportLists.length === 0 ? (
                      <tr>
                        <td colSpan="13">No data found</td>
                      </tr>
                    ) : (
                      monthlyRevenueReportLists?.map?.((item, index) => (
                        <tr key={item.id}>
                          <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                          <td>{item?.jen_idBookingDetailsAdminUsers?.name}</td>
                          <td>{item?.vehicle_number}</td>
                          <td>{item?.count}</td>
                          <td>{item?.distance}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              </div>

              <div className="mt-3 d-lg-flex align-items-center text-center pb-1">
                <div className="d-inline-block me-3">
                  <label className="me-1">Display :</label>
                  <select className="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span className="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(reportCount / paginate)}</strong> </span>
                <RoundedPagination
                  currentPage={page}
                  totalPages={Math.ceil(reportCount / paginate)}
                  onPageChange={(pageNumber) => setPage(pageNumber)}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingMaster && (<Preloader/>)}
      {loadingReport && (<Preloader/>)}
    </>
  );
};

export default Dashboard;
