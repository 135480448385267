// useDebounce.js
import { useEffect, useCallback } from 'react';

const useDebounce = (callback, delay, dependencies) => {
  const memoizedCallback = useCallback(callback, dependencies);

  useEffect(() => {
    const handler = setTimeout(() => {
      memoizedCallback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [memoizedCallback, delay]);
};

export default useDebounce;
