import React, { useCallback, useEffect , useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getDashboardCount } from "../redux/actions/DashboardActions";
import styled from "styled-components";
import { Row, Col, Card, Table, Pagination, Button, Badge } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { deleteCityData, getCityList, updateCityStatusData } from "../../redux/actions/CityActions";
import RoundedPagination from "../../Components/Paginate";
import FormInput from "../../Components/FormInput";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert2';
import { toast } from "react-toastify";
import { logout } from "../../redux/actions/AuthActions";
import useDebounce from "../../hooks/useDebounce";
import { getDisplayDateTimeFormat } from "../../util/generalUtills";
import Preloader from "../../Components/Preloader";
import classNames from "classnames";



const Dashboard = () => {
  const { t } = useTranslation();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const {
    AuthReducer: { auth,errors,loading },
    cityReducer: {
      cityLists,
      loadingCity,
      errorsCity,
      cityCount,
      changedCityStatus,
    },
  } = useSelector((state) => state);
  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState('desc');
  const indexOfLastItem = (page + 1) * paginate;
  const indexOfFirstItem = indexOfLastItem - paginate;
  
  // useEffect(() => {
  //   dispatch(getCityList(auth?.token, paginate, page, search, sort));
  //   console.log('cityLists',cityLists);
  // }, [auth, paginate, page, search, sort]);

  const fetchCityList = useCallback(() => {
    if (auth?.token) {
      dispatch(getCityList(auth.token, paginate, page, search, sort));
    }
  }, [auth, paginate, page, search, sort, dispatch]);

  useDebounce(fetchCityList, 500, [auth, paginate, page, search, sort]);

  useEffect(() => {
    if(errors)
    {
      if(errors?.status==401)
      {
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  },[errors])



  const handlePaginateChange = (e) => {
    const value = e.target.value === "All" ? cityCount : parseInt(e.target.value);
    setPaginate(value);
    setPage(1); // Reset to the first page whenever the paginate value changes
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleDeleteRequest = (id) => {
    swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, delete it!",
    })
    .then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteCityData(auth?.token,id))
      }
    });
  };

  const handleStatusChangeRequest = (id, newStatus) => {
    swal.fire({
      title: "Are you sure?",
      text: `Do you want to change the status to ${newStatus}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#28bb4b",
      cancelButtonColor: "#f34e4e",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(updateCityStatusData(auth.token, id, newStatus));
      }
    });
  };


  useEffect(() => {
    //console.log(changedCityStatus);
    if(changedCityStatus?.status)
    {
      swal.fire({
        show: true,
        title: "Deleted!",
        text: changedCityStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_CITY_STATUS' });
      dispatch(getCityList(auth?.token, paginate, page, search, sort));
    }
    else if(changedCityStatus.success)
    {
      swal.fire({
        show: true,
        title: "Great !",
        text: changedCityStatus.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_CITY_STATUS' });
      dispatch(getCityList(auth?.token, paginate, page, search, sort));
    }
  },[changedCityStatus])


  useEffect(() => {
    if (errorsCity && Object.keys(errorsCity).length > 0) {
      if(errorsCity?.status==401){
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
      }
    }
  }, [errorsCity]);


  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("City"), path: "/City", active: true },
        ]}
        title={t("City")}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
            <div className="mb-2">
              <Row>
                <Col lg={3}>
                  <div className="d-flex align-items-center">
                  <input
                    type="search"
                    placeholder="Search..."
                    className="form-control ms-1"
                    autoComplete="off"
                    value={search}
                    onChange={handleSearchChange}
                  />
                  </div>
                </Col>
                <Col lg={9}>
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="button-list">
                      <Link to="/Add-City">
                        <Button variant="success">
                          <i className="mdi mdi-clipboard-plus-outline me-1"></i>Add
                        </Button>
                      </Link>
                      <Button variant="dark">
                        <i className="mdi mdi-printer me-1"></i>Print
                      </Button>
                      <Button variant="info">
                        <i className="mdi mdi-file-excel me-1"></i>Excel
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

            <div >
              <Table className="mb-0 table-bordered" responsive size="sm" striped>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>State Name</th>
                    <th>City Code</th>
                    <th>City Name</th>
                    <th>Status</th>
                    <th>Registered At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {cityLists.length === 0 ? (
                  <tr>
                    <td colSpan="6">No data found</td>
                  </tr>
                ) : (<>
                  {cityLists?.map?.((item, index) => {
                    return (
                      <tr key={item.id}>
                        <th scope="row">{index + 1 + (page - 1) * paginate}</th>
                        <td>{item?.cities_state?.state}</td>
                        <td>{item?.city_cd}</td>
                        <td>{item?.city_name}</td>
                        <td>{item?.status=="Active" ? <Badge className={"bg-success"}>{item?.status}</Badge> : <><Badge className={"bg-danger"}>{item?.status}</Badge></>}</td>
                        <td>{getDisplayDateTimeFormat(item?.createdAt)}</td>
                        <td>
                          {item?.status === "Active" && (
                            <>
                              <Link to="#" className="action-icon text-success" onClick={() => handleStatusChangeRequest(item?.id , "In-Active")}>
                                {" "}
                                <i className="mdi mdi-eye"></i>
                              </Link>
                            </>
                          )}
                          {item?.status === "In-Active" && (
                            <>
                              <Link to="#" className="action-icon text-warning" onClick={() => handleStatusChangeRequest(item?.id, "Active")}>
                                {" "}
                                <i className="mdi mdi-eye-off"></i>
                              </Link>
                            </>
                          )}

                          <Link to={`/Update-City/${item?.id}`} className="action-icon text-info"> 
                            <i className="mdi mdi-square-edit-outline"></i>
                          </Link>

                          <Link to="#" className="action-icon text-danger" onClick={() => handleDeleteRequest(item?.id)} >
                            {" "}
                            <i className="mdi mdi-delete"></i>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}</>
                )}
                </tbody>
              </Table>
            </div>
            <div class="mt-3 d-lg-flex align-items-center text-center pb-1">
              
                <div class="d-inline-block me-3">
                  <label class="me-1">Display :</label>
                  <select class="form-select d-inline-block w-auto" onChange={handlePaginateChange}>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>
                <span class="me-3">Page <strong>{page}</strong> of <strong>{Math.ceil(cityCount / paginate)}</strong> </span>
                <RoundedPagination
                      currentPage={page}
                      totalPages={Math.ceil(cityCount / paginate)}
                      onPageChange={(pageNumber) => setPage(pageNumber)}
                    />
              
              
            </div>
            
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingCity && (<Preloader/>)}
    </>
  );
};

export default Dashboard;
