import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Card, Button, Spinner, Form } from "react-bootstrap";
import PageTitle from "../../Components/PageTitle";
import { useTranslation } from "react-i18next";
import { getCityListById, saveCityData, updateCityData } from "../../redux/actions/CityActions";
import { getAllStateList } from "../../redux/actions/MasterActions";
import FormInput from "../../Components/FormInput";
import { useNavigate, useParams } from "react-router-dom";
import { logout } from "../../redux/actions/AuthActions";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import swal from 'sweetalert2';
import Preloader from "../../Components/Preloader";

const Dashboard = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();
  const {
    AuthReducer: { auth, loading, errors },
    cityReducer: {
      loadingCity,
      errorsCity,
      cityLists,
      changedCityStatus,
    },
    masterReducer: {
      masterStateLists,
      loadingMaster,
      errorsMaster,
    },
  } = useSelector((state) => state);

  useEffect(() => {
    if (auth?.token) {
      dispatch(getAllStateList(auth.token));
    }
  }, [auth?.token]);

  useEffect(() => {
    if(id)
    {
      dispatch(getCityListById(auth?.token, id));
    }
  }, [auth, id]);

  
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);
    if(id)
    {
      dispatch(updateCityData(auth?.token, formData,id));
    }
    else
    {
      dispatch(saveCityData(auth?.token, formData));
    }
  };

  useEffect(()=>{
    if(id)
    {
      if(cityLists && Object.keys(cityLists).length>0)
      {
        setFormData({
          city_cd: cityLists?.city_cd,
          city_name: cityLists?.city_name,
          state_id: cityLists?.state_id ,
        });
      }
    }
    
  },[cityLists])

  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      if (errors?.status === 401) {
        dispatch(logout());
        toast.error(errors?.data?.message);
        navigate('/login');
        window.location.reload();
      }
    }
  }, [errors, dispatch, navigate]);

  useEffect(() => {
    if (errorsCity && Object.keys(errorsCity).length > 0) {
      if(errorsCity?.status==401){
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
        //window.location.reload();
      }
      else if (errorsCity?.status === 409) {
        swal.fire({
          show: true,
          title: "Oops!",
          text: errorsCity?.data?.message,
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
        dispatch({ type: 'RESET_DELETE_CITY_STATUS' });
      } else if (errorsCity?.status === 400) {
        // Handle other status
      } else {
        dispatch({ type: 'RESET_DELETE_CITY_STATUS' });
      }
    }
  }, [errorsCity, dispatch]);

  useEffect(() => {
    if (errorsMaster && Object.keys(errorsMaster).length > 0) {
      if(errorsMaster?.status==401){
        dispatch(logout())
        toast.error(errors?.data?.message);
        navigate('/login');
      }
    }
  }, [errorsMaster]);

  useEffect(() => {
    if (changedCityStatus?.success) {
      swal.fire({
        show: true,
        title: "Well done!",
        text: changedCityStatus?.message,
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      dispatch({ type: 'RESET_DELETE_CITY_STATUS' });
      navigate(-1);
    }
  }, [changedCityStatus, dispatch, navigate]);

  useEffect(() => {
    setFormData({});
    dispatch({ type: 'RESET_DELETE_CITY_STATUS' });
  }, [dispatch]);

  const inputHandler = (e) => {
    const { value, name } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const inputHandlerCaps = (e) => {
    const { value, name } = e.target;
    const processedValue = value.replace(/\s+/g, '').toUpperCase(); // Remove spaces and convert to uppercase
    setFormData({ ...formData, [name]: processedValue });
  };

  const selectHandler = (selectedData, name) => {
    console.log(selectedData);
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: selectedData[0].id
    }));
  };

  const customSelectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      '&:hover': {
        borderColor: errors?.data?.state_code ? 'red' : base.borderColor,
      }
    }),
  };

  return (
    <>
      <PageTitle
        breadCrumbItems={[
          { label: t("City"), path: "City" },
          { label: t("Add City"), path: "/Add-City", active: true },
        ]}
        title={t("Add City")}
      />
      <Row>
        <Col lg={4}></Col>
        <Col lg={4}>
          <Card>
            <Card.Body>
              {errorsCity?.data?.message && (
                <div className="alert alert-danger">
                  {errorsCity?.data?.message}
                </div>
              )}

              <Form.Group className="mb-3">
                <Form.Label>{t("State")}</Form.Label>
                <Select
                  options={masterStateLists}
                  values={masterStateLists.filter((state) => state.id === formData.state_id)}
                  name="state_id"
                  labelField={"state"}
                  valueField={"id"}
                  onChange={(selectedData) => selectHandler(selectedData, "state_id")}
                  styles={customSelectStyles}
                />
                
                {errorsCity?.data?.state_id && (
                  <div className="invalid-feedback d-block">
                    <p className="text-danger">{errorsCity?.data?.state_id}</p>
                  </div>
                )}
              </Form.Group>

              <FormInput
                label={t("City Code")}
                type="text"
                errors={errorsCity?.data}
                value={formData.city_cd}
                name="city_cd"
                onChange={inputHandlerCaps}
                containerClass={"mb-3"}
              />

              <FormInput
                label={t("City Name")}
                type="text"
                errors={errorsCity?.data}
                value={formData.city_name}
                name="city_name"
                onChange={inputHandler}
                containerClass={"mb-3"}
              />
              
              <div className="d-flex justify-content-center">
                {loading ? 
                  <Button color="primary" disabled>
                    <Spinner
                      className="spinner-border-sm me-1"
                      tag="span"
                      color="white"
                    />
                    Please wait ...
                  </Button>
                :
                  <Button type="button" onClick={handleSubmit} disabled={loading}>
                    Submit
                  </Button>
                }
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {loadingCity && (<Preloader/>)}
      {loadingMaster && (<Preloader/>)}
    </>
  );
};

export default Dashboard;