import axios from "axios";


export const getTripReportList =
  (token, limit = 10, page = 1, search = "", sort = "desc", vendorId = "", 
    vehicleId = "", driverId = "", startDate = "", endDate = "") =>
  async (dispatch) => {
    try
    {
      dispatch({ type: "GET_TRIP_REPORT_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Trip-Report`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
            vendorId: vendorId,
            vehicleId: vehicleId,
            driverId: driverId,
            startDate: startDate,
            endDate: endDate,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_TRIP_REPORT_FULFILLED",
        payload: response.data?.data,
        reportCount: response.data?.count,
      });
    }
    catch (error)
    {
      console.log(error);
      dispatch({
        type: "GET_TRIP_REPORT_REJECTED",
        payload: error.response,
      });
    }
  };


export const getRevenueReportList =
  (token, limit = 10, page = 1, search = "", sort = "desc", vendorId = "", 
    vehicleId = "", driverId = "", startDate = "") =>
  async (dispatch) => {
    try
    {
      dispatch({ type: "GET_REVENUE_REPORT_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Revenue-Report`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
            vendorId: vendorId,
            vehicleId: vehicleId,
            driverId: driverId,
            startDate: startDate,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_REVENUE_REPORT_FULFILLED",
        payload: response.data?.data,
        reportCount: response.data?.count,
      });
    }
    catch (error)
    {
      console.log(error);
      dispatch({
        type: "GET_REVENUE_REPORT_REJECTED",
        payload: error.response,
      });
    }
  };


export const getMonthlyRevenueReportList =
  (token, limit = 10, page = 1, search = "", sort = "desc", vendorId = "", 
    vehicleId = "", driverId = "", startDate = "") =>
  async (dispatch) => {
    try
    {
      dispatch({ type: "GET_MONTHLY_REVENUE_REPORT_PENDING" });

      const response = await axios.get(
        `/api/admin/v1/Monthly-Revenue-Report`,
        {
          params: {
            limit: limit,
            page: page,
            search: search,
            sort: sort,
            vendorId: vendorId,
            vehicleId: vehicleId,
            driverId: driverId,
            startDate: startDate,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log('response.data?.data',response.data?.data);
      dispatch({
        type: "GET_MONTHLY_REVENUE_REPORT_FULFILLED",
        payload: response.data?.data,
        reportCount: response.data?.count,
      });
    }
    catch (error)
    {
      console.log(error);
      dispatch({
        type: "GET_MONTHLY_REVENUE_REPORT_REJECTED",
        payload: error.response,
      });
    }
  };