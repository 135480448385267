import Routes from "./Routes";
import { Provider } from "react-redux";
import Store from "./redux/Store";
import "react-toastify/dist/ReactToastify.css";
import axios from "./axios";
import SocketContext from "./Context/SocketContext";
import './assets/scss/Default.scss';
import './assets/scss/Landing.scss';
import "./assets/scss/Icons.scss";
import useOnlineStatus from "./hooks/useOnlineStatus";

function App() {
  const isOnline = useOnlineStatus();
  return (
    <Provider store={Store}>
      <Routes />
    </Provider>
  );
}

export default App;
