import React, { forwardRef } from "react";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import { getDateTimeMysqlFormat, getDateTimeMysqlGivenFormatYmdhi } from "../util/generalUtills";

/* Datepicker with Input */
const DatepickerInput = forwardRef((props, ref) => {
  const onDateValueChange = () => {
    console.log("date value changed");
  };
  return (
    <input
      type="text"
      className={classNames("form-control col-12", props.inputClass)}
      onClick={props.onClick}
      value={props.value}
      onChange={onDateValueChange}
      style={{width:'100%'}}
      ref={ref}
    />
  );
});

/* Datepicker with Addon Input */
const DatepickerInputWithAddon = forwardRef((props, ref) => (
  <div className="input-group input-group-sm" ref={ref}>
    <input
      type="text"
      className={classNames("form-control", props.inputClass)}
      onClick={props.onClick}
      value={props.value}
      style={{width:'100%'}}
      readOnly
    />
    <span className="input-group-text bg-blue border-blue text-white">
      <i className="mdi mdi-calendar-range"></i>
    </span>
  </div>
));

const HyperDatepicker = (props) => {
  const input =
    props.hideAddon === true ? (
      <DatepickerInput inputClass={props.inputClass} value={props.value} />
    ) : (
      <DatepickerInputWithAddon inputClass={props.inputClass} value={props.value} />
    );

  return (
    <>
      {/* date picker control */}
      <DatePicker
        customInput={input}
        timeIntervals={props.tI}
        selected={props.value}
        value={props.value}
        onChange={(date) => props.onChange(date)}
        showTimeSelect={props.showTimeSelect}
        timeFormat={props.timeFormat || "HH:mm"}
        timeCaption={props.timeCaption}
        dateFormat={props.dateFormat || "yyyy-MM-dd"}
        minDate={props.minDate}
        maxDate={props.maxDate}
        monthsShown={props.monthsShown}
        showTimeSelectOnly={props.showTimeSelectOnly}
        inline={props.inline}
        autoComplete="off"
      />
    </>
  );
};

export default HyperDatepicker;
