import React from 'react';
import { Pagination } from 'react-bootstrap';

const RoundedPagination = ({ currentPage, totalPages, onPageChange }) => {
  const items = [];
  const maxItemsToShow = 10;

  const getPageNumbers = () => {
    const pages = [];
    const halfWindow = Math.floor(maxItemsToShow / 2);

    let startPage = Math.max(1, currentPage - halfWindow);
    let endPage = Math.min(totalPages, currentPage + halfWindow);

    if (currentPage <= halfWindow) {
      endPage = Math.min(totalPages, maxItemsToShow);
    }

    if (currentPage + halfWindow >= totalPages) {
      startPage = Math.max(1, totalPages - maxItemsToShow + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (startPage > 1) {
      if (startPage > 2) pages.unshift('...');
      pages.unshift(1);
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) pages.push('...');
      pages.push(totalPages);
    }

    return pages;
  };

  const pageNumbers = getPageNumbers();

  pageNumbers.forEach((number, index) => {
    if (number === '...') {
      items.push(<Pagination.Ellipsis key={index} disabled />);
    } else {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => onPageChange(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
  });

  return (
    <Pagination className="pagination-rounded d-inline-flex ms-auto align-item-center mb-0">
      <Pagination.Prev
        onClick={() => onPageChange(Math.max(currentPage - 1, 1))}
        disabled={currentPage === 1}
      />
      {items}
      <Pagination.Next
        onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

export default RoundedPagination;
